import React from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XTextBox } from "../../components/common/inputs/XTextBox";

const SearchCompanies = (props: any) => {

  let initialValues: any = {
    term: props.term
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {
            <>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <XTextBox
                            key="term"
                            id="term"
                            name={"term"}
                            type="text"
                            placeholder="Company name, email, etc..."
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchCompanies;
