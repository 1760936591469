import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { SupplierFields } from "../../fields/SupplierFields";
import { SupplierSchema } from "../../schemas/SupplierSchema";
import { Endpoints } from "../../utils/Endpoints";
import { ISupplier } from "../../interfaces/suppliers/ISupplier";
import SearchCompanies from "./search";
import { useHistory } from "react-router-dom";

const Suppliers = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [showSupplierDialog, setShowSupplierDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    term: ""
  });

  const itemsPerPage: number = 25;
  const history = useHistory();

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true
    },
    {
      dataField: "city",
      text: "City",
      sort: false
    },
    {
      dataField: "phone",
      text: "Telephone",
      sort: false
    },
    {
      dataField: "country_id",
      text: "Country",
      sort: true
    },
    {
      dataField: "contactname",
      text: "Contact Name",
      sort: false
    },
    {
      dataField: "balance",
      text: "Balance",
      sort: false
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(item, "details")}>
            <i className="mdi mdi-eye font-size-16 text-success me-1"></i>Details
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption(item, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const handleOption = (item: any, type: string) => {
    
    if (type == "edit") {
      setInitialValues(item);
      setShowSupplierDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        suppliertype: ""
      });
      setShowSupplierDialog(true);
    } else if (type == "details") {
      history.push("/inventory/suppliers/" + item.id);
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: itemsPerPage,
    totalSize: totalRecords,
    custom: true
  };

  const request = new ApiCore();

  useEffect(() => {
    searchCompanies({term: ""})
  }, []);

  const searchCompanies = (values: any) => {
    setInitialValues({term: values.term})
    toast
    .promise(
      request
        .get(Endpoints.companies, { params: { term: values.term, per_page: itemsPerPage } }),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          const { companies, totalRecords } = response.data;
          setSuppliers(companies);
          setTotalRecords(totalRecords);
          setLoading(false);
        });
  };


  const handleUpdate = (item: ISupplier) => {
    const items = suppliers.map((i: ISupplier) => {
      if (i.id === item.id){
        return {...i, ...item}
      }

      return i
    })
    setSuppliers(items);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Suppliers and customers - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Inventory" breadcrumbItem="Suppliers and customers" />

          <XModal title={initialValues.id ? "Edit a customer/supplier" : "Add a customer/supplier"}
                  width={"lg"}
                  open={showSupplierDialog}
                  toggle={() => setShowSupplierDialog(!showSupplierDialog)}
                  onClose={() => setShowSupplierDialog(false)}>

            <CreateForm
              fields={SupplierFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.companies + "/" + initialValues.id : Endpoints.companies}
              submitButtonLabel={"Save"}
              validationSchema={SupplierSchema}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowSupplierDialog(false)} />

          </XModal>

          <Row>
            <Col className="col-xxl-12" lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Suppliers / Customers
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null,"new")}>
                        Add a New
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading companies. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={suppliers}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <SearchCompanies
                                    term={initialValues.term}
                                    handleSearch={(values: any) => {
                                      searchCompanies(values);
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Suppliers;