import React from "react";
import { Card } from "reactstrap";
import { amountToCurrency } from "../../utils/Helpers";

const Widget = (props: any) => {
    const {data = []} = props;
    return (
        <React.Fragment>
            <Card>
                <div>
                    <ul className="list-group list-group-flush">
                    { (data || []).map((record: any, key: number) => (
                        <li className="list-group-item" key={key}>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm">
                                        <div className="avatar-title rounded-circle font-size-12">
                                            <i className={'fas fa-user'}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <p className="text-muted mb-1">{record[0] ? record[0].name: ""}</p>
                                    <h5 className="font-size-16 mb-0">{record[1] ? amountToCurrency(record[1]) : ""}</h5>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                    <div className={'badge badge-soft-danger ms-2'}>{record[0] ? amountToCurrency(record[0].balance) : ""}<i className={"uil uil-arrow-up-right text-success ms-1"}></i></div>
                                </div>
                            </div>
                        </li>
                         ))}
                    </ul>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default Widget;