import React from "react";
import { Table, CardBody, Card } from "reactstrap";
import { formattedDate, amountToCurrency } from "../../utils/Helpers";

const Channels = (props: any) => {
    const {invoices, title = "Recent Sales"} = props;
    return (
        <React.Fragment>
            <Card className="card-h-100">
                <CardBody>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="card-title text-truncate mb-3">{title}</h5>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(invoices || []).map((invoice: any, key: number) => (
                                    <tr key={key}>
                                        <th scope="row"><a href={`/invoices-detail/${invoice.id}`}>{formattedDate(invoice.created)}</a></th>
                                        <td>{invoice.company ? invoice.company.name : ""}</td>
                                        <td>{amountToCurrency(invoice.tot)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Channels;