import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/common/Breadcrumb";
import { Loading } from "../../components/common/Loading";

import {
  Button,
  Card, CardBody, Col,
  Container,
  Row
} from "reactstrap";

import { FieldArray, Form, Formik} from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XTextArea } from "../../components/common/inputs/XTextArea";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Endpoints } from "../../utils/Endpoints";
import InvoiceSummary from "../Sales/InvoiceSummary";
import { ApiCore } from "../../helpers/api_helper";
import { amountToCurrency, currentDate } from "../../utils/Helpers";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";
import { AxiosError, AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import { SupplierFields } from "../../fields/SupplierFields";
import { SupplierSchema } from "../../schemas/SupplierSchema";
import CreateForm from "../../components/forms/CreateForm";
import { useLocation, useHistory } from "react-router-dom";
import { ISupplier } from "../../interfaces/suppliers/ISupplier";
import { XSearchableSelect } from "../../components/common/inputs/XSearchableSelect";

const NewSale = () => {

  const [defaults, setDefaults] = useState<any>({});
  const [companies, setCompanies] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingReceipt, setLoadingReceipt] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";
  const [showSupplierDialog, setShowSupplierDialog] = useState<boolean>(false);
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const getDefaultAccount = () => {
    if (defaults.accounts) {
      if (invtype == "sale" && defaults.accounts.receivable) {
        return defaults.accounts.receivable;
      } else if (invtype == "purchase" && defaults.accounts.payable) {
        return defaults.accounts.payable;
      }
    }
    return 0;
  }
  const [receipt, setReceipt] = useState<any>(null);

  const history = useHistory();

  const request = new ApiCore();

  const receiptId = params.get("id") ? params.get("id") : null;
  const invoiceId = params.get("invoiceId") ? params.get("invoiceId") : null;
  const companyId = params.get("companyId") ? params.get("companyId") : "";
  const balance = params.get("balance") ? params.get("balance") : 0;

  if (receiptId) {
    useEffect(() => {
      request
        .get(Endpoints.receipts + "/" + receiptId)
        .then((response: AxiosResponse) => {
          setReceipt(response.data);     
          setLoadingReceipt(false);    
        })
        .catch(error => {
          console.error(error);
        });
    }, []);
  }

  useEffect(() => {
    request
      .get(Endpoints.defaults)
      .then((response: AxiosResponse) => {
        setDefaults(response.data);
        setLoading(false);
        if (!receiptId) {
          setLoadingReceipt(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {

    request.get(Endpoints.companies, {})
      .then((response: AxiosResponse) => {
        const { companies } = response.data;
        let options: any = []
        options = companies.map((data: any) => ({
          value: data.id,
          label: data.name || data.taxid,
          data: JSON.stringify(data)
        }))
        setCompanies(options);
        initialValues.company_id = "";

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  let initialValues: any = {}
  if (receipt) {
    initialValues = {
      company_id: receipt.company_id ? receipt.company_id : "",
      account_id: receipt.account_id ? receipt.account_id : "",
      currency_id: receipt.currency ? receipt.currency.id : "",
      currency: receipt.currency,
      bank_id: receipt.bank_id ? receipt.bank_id : "",
      whduty_id: receipt.whduty_id ? receipt.whduty_id : "",
      created: receipt.created ? receipt.created : "",
      rate: receipt.rate ? receipt.rate : 1,
      items: receipt.items.map((item: any) => {
        return item;
      })
    }
  } else {
    initialValues = {
      company_id: companyId,
      account_id: defaults.accounts ? defaults.accounts.cash : "",
      currency_id: defaults.currency ? defaults.currency.id : "",
      currency: defaults.currency,
      invoice_id: invoiceId ? invoiceId : "",
      bank_id: "",
      whduty_id: "",
      created: currentDate(),
      rate: defaults.currency ? defaults.currency.rate : 1,
      items: [
        {
          craccountid: defaults.accounts ? (invoiceId ? defaults.accounts.receivable : defaults.accounts.liability) : "",
          particulars: "",
          amount: balance
        }
      ]
    }
  }


  const computeSubTotal = (items: [any]) => {
    const sum = items.reduce((accumulator, object) => {
      return accumulator + Number(object.amount);
    }, 0);
    return sum;
  }

  const handleClickNewCompany = () => {
    setShowSupplierDialog(true);
  };

  const handleCreateCompany = (supplier: ISupplier, f: any) => {
    const updatedCompanies = [...companies, {
      value: supplier.id,
      label: supplier.name,
      data: JSON.stringify(supplier)
    }]
    setCompanies(updatedCompanies);
    f('company_id',supplier.id);
  };

  const handleSubmit = (values: any) => {
    const request_body = values;
    request_body.generaljournals_attributes = request_body.items;
    if (receiptId) {
      request_body.id = receiptId;
      request.update(Endpoints.receipts + "/" + receiptId, request_body)
        .then((response: AxiosResponse) => {
          history.push('/transactions/receipts/' + response.data.id)
        }).catch((error: AxiosError) => {
          console.log(error)
        })
    } else {
      request.create(Endpoints.receipts, request_body)
        .then((response: AxiosResponse) => {
          history.push('/transactions/receipts/' + response.data.id)
        }).catch((error: AxiosError) => {
          console.log(error)
        })
    }
  };

  const getCurrencyCode = (currency: any) => {
    return currency ? currency.code : "UGX";
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Make a sale | Treda</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Transactions" breadcrumbItem="Make a sale" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {(loading || loadingReceipt) && <Loading label="Loading receipt. Please wait." />}
                  {(!loading && !loadingReceipt) && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}>

                        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                          <>
                          <XModal title={"Add a Customer"}
                            width={"lg"}
                            open={showSupplierDialog}
                            toggle={() => setShowSupplierDialog(!showSupplierDialog)}
                            onClose={() => setShowSupplierDialog(false)}>

                            <CreateForm
                              fields={SupplierFields}
                              initialValues={initialValues}
                              enableReinitialize={true}
                              submitEndpoint={Endpoints.companies}
                              submitButtonLabel={"Create customer"}
                              validationSchema={SupplierSchema}
                              debug={false}
                              handleUpdate={(supplier: ISupplier) => {
                                handleCreateCompany(supplier, setFieldValue);
                              }}
                              onClose={() => setShowSupplierDialog(false)} />

                          </XModal>
                          <Form>
                            <Row>
                              <Col lg={2}>
                                <XDatePickerField key="created" id="created"
                                  name={"created"}
                                  label={"Date"}
                                  touched={touched}
                                  onChange={setFieldValue}
                                  value={values.created}
                                  errors={errors} />
                              </Col>
                              <Col lg={3}>
                                <XSearchableSelect
                                    key="company_id"
                                    id="company_id"
                                    value={values.company_id}
                                    name={"company_id"}
                                    options={companies}
                                    label={"Customer"}
                                    handleNewClick={e => {
                                      handleClickNewCompany();
                                    }}
                                    onChange={(value: any) => {
                                      setFieldValue("company_id", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                              </Col>

                              <Col lg={3}>
                                <XSelect key="account_id" id="account_id"
                                  name={"account_id"}
                                  dataSrc={Endpoints.accounts}
                                  dataType={"accounts"}
                                  label={"Debit Account"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                              <Col lg={2}>
                                <XSelect key="whduty_id" id="whduty_id"
                                  name={"whduty_id"}
                                  dataSrc={Endpoints.whduties}
                                  dataType={"whduties"}
                                  label={"Withholding Tax"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>

                              <Col lg={2}>
                                <XSelect
                                  key="bank_id"
                                  id="bank_id"
                                  name={"bank_id"}
                                  dataSrc={Endpoints.banks}
                                  dataType={"banks"}
                                  label={"Bank"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={2}>
                                <XSelect key="currency_id" id="currency_id"
                                  name={"currency_id"}
                                  dataSrc={Endpoints.currencies}
                                  onChange={(field: any) => {
                                    const data = JSON.parse(field.target.selectedOptions[0].dataset.raw);

                                    setFieldValue(field.target.name, data.id);
                                    setFieldValue(`currency`, data);
                                    setFieldValue(`rate`, data.rate);
                                  }}
                                  dataType={"currencies"}
                                  label={"Currency"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                              <Col lg={1}>
                                <XTextBox
                                  key="rate"
                                  id="rate"
                                  type={"text"}
                                  name={"rate"}
                                  label={"Rate"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <div className={"mt-4 border-top border-bottom pt-2 bg-light"}>
                                  <Row>
                                    <Col lg={4}><h6>Particulars</h6></Col>
                                    <Col lg={4}><h6>Account</h6></Col>
                                    <Col lg={3}><h6>Amount</h6></Col>
                                    <Col lg={1}></Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={12}>
                                <FieldArray name={"items"} render={arrayHelpers => (
                                  <div className={"py-3"}>
                                    {values.items &&
                                      values.items.map((item: any, index: number) => (
                                        <Row key={index}>
                                          <Col lg={4}>
                                            <XTextBox
                                              id={`items.${index}.particulars`}
                                              type={"text"}
                                              name={`items.${index}.particulars`}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={4}>
                                            <XSearchableSelect
                                              dataType={"accounts"}
                                              dataSrc={Endpoints.accounts}
                                              id={`items.${index}.craccountid`}
                                              name={`items.${index}.craccountid`}
                                              value={values.items[index].craccountid}
                                              onChange={(value: any) => {
                                                setFieldValue(`items.${index}.craccountid`, value.value);
                                              }}
                                              touched={touched} />
                                          </Col>
                                          <Col lg={3}>
                                            <XTextBox
                                              id={`items.${index}.amount`}
                                              type={"text"}
                                              name={`items.${index}.amount`}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={1}>
                                            <Button className={"btn btn-soft-danger"} to={"#"}>
                                              <i className="fa fa-times"
                                                onClick={() => arrayHelpers.remove(index)}></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      )
                                      )}
                                    <Button
                                      className={"btn btn-soft-primary"}
                                      type="button"
                                      onClick={() => arrayHelpers.push({
                                        craccountid: "",
                                        particulars: "",
                                        amount: "",
                                      })}>
                                      <i className={"mdi mdi-plus"}></i> Add Item
                                    </Button>

                                  </div>
                                )} />
                              </Col>

                            </Row>

                            <Row>
                              <XTextArea id="remarks" label="Remarks" name="remarks" size={{ lg: 12 }} touched={touched} />
                            </Row>

                            <Row>
                              <Col lg={3}>
                                <InvoiceSummary label={"Sub total"} icon="mdi mdi-calculator" value={getCurrencyCode(values.currency) + " " + amountToCurrency(computeSubTotal(values.items))} />
                              </Col>
                            </Row>

                            <div className="modal-footer">
                              <Button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                Save
                              </Button>
                            </div>

                          </Form>
                          </>

                        )}


                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewSale;