import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { confirm } from "react-confirm-box";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";

const Details = () => {
  const [memo, setMemo] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.memos + "/" + id)
      .then((response: AxiosResponse) => {
        setMemo(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleEdit = async (id: string) => {
    history.push("/transactions/memos/new?id=" + id);
  };

  const onCancel = async (id: any) => {
    const result = await confirm("Do you really want to cancel the memo?");
    if (result) {
      handleCancel(id);
      return;
    }
  };

  const handleCancel = (id: any) => {
    toast
      .promise(request.get(Endpoints.cancelMemos, { params: {id: id} }), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            console.log(data);
            return "Memo has been canceled.";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        //console.log(response);
      });
  };

  //Print the Memo
  const printMemo = () => {
    window.print();
  };

  const handlePdf = (id: string) => {
    toast
    .promise(request
        .get(Endpoints.memos + '/' + id, { params: { pdf: true } }, "arraybuffer"),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], {type: type})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `memo-${+ new Date()}.pdf`
          link.click()
        });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Memo Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Memos" breadcrumbItem="Memo Detail" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Memo</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handlePdf(memo.id);
                        }}
                      >
                        PDF Version
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleEdit(memo.id);
                        }}
                      >
                        Edit Memo
                      </DropdownItem>
                      {memo.efris_code && (
                        <DropdownItem
                          to="#"
                          onClick={() => {
                            onCancel(memo.id);
                          }}
                        >
                          Cancel Memo
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading memo. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <div className="memo-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Memo For: </h5>
                            <h5 className="font-size-15 mb-2">
                              {memo.company.name}
                            </h5>
                            <p className="mb-1">
                              {memo.company.street} {memo.company.city}
                            </p>
                            <p className="mb-1">{memo.company.contactemail}</p>
                            <p>{memo.company.contractphone}</p>
                          </div>
                        </Col>
                        <Col sm={4}>
                          {memo.efris_code ? (
                            <Row>
                              <Col sm={4}>
                                <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                                  <QRCode
                                  size={256}
                                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                  value={memo.efris_code}
                                  viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <h5>URA EFRIS</h5>
                                <div>
                                  <b>INVOICE FDN:</b> {memo.efris_invoice_fdn}
                                </div>
                                <div>
                                  <b>MEMO FDN:</b> {memo.efris_invoice_no}
                                </div>
                                <div>
                                  <b>Device No:</b> {memo.efris_device_no}
                                </div>
                                <div>
                                  <b>Verification Code:</b> {memo.efris_verification_code}
                                </div>
                                <div>
                                  <b>Date:</b> {memo.efris_date_time}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                        <Col sm={2}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">Memo No: </h5>
                              <p>#{memo.id}</p>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">Memo Date: </h5>
                              <p>{formattedDate(memo.created)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Particulars</th>
                                <th className="text-end">Price ({memo.currency.code})</th>
                                <th className="text-end">Quantity</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Total ({memo.currency.code})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {memo.items.map((item: any, index: number) => {
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                          item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                            ? item.product.name
                                            : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td className="text-end"> {amountToCurrency(item.rate)}</td>
                                    <td className="text-end">{amountToCurrency(-1 * item.quantity)}</td>
                                    <td className="text-end">
                                      {amountToCurrency(-1 * item.amount)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={4}
                                  className="text-end"
                                >
                                  Total
                                </th>
                                <td className="text-end">
                                  {memo.currency.code}{" "}
                                  {amountToCurrency(-1 * memo.total)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-print-none mt-4">
                          <div className="float-end">
                            <Link
                              to="#"
                              className="btn btn-success me-1"
                              onClick={printMemo}
                            >
                              <i className="fa fa-print"></i>
                            </Link>
                            <Link to="#" className="btn btn-primary w-md">
                              Send
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Details;
