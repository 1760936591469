export const Endpoints = {
  login: "/auth/login",
  products: "/api/products",
  emailInvoice: "/api/invoices/email",
  emailRequisition: "/api/requisitions/email",
  emailReceipt: "/api/receipts/email",
  efris_products: "/api/products/efris",
  productGroups: "/api/productgroups",
  accounts: "/api/accounts",
  invoices: "/api/invoices",
  salesOrders: "/api/sales",
  saleattachments: "/api/saleattachments",
  saleupdates: "/api/saleupdates",
  stockrecords: "/api/stockrecords",
  receipts: "/api/receipts",
  memos: "/api/memos",
  cancelMemos: "/api/memos/cancel",
  vouchers: "/api/vouchers",
  defaults: "/api/invoices/defaults",
  companies: "/api/companies",
  currencies: '/api/currencies',
  taxes: '/api/taxes',
  userProfile:"/api/users/profile",
  whduties: '/api/whduties',
  locations: '/api/locations',
  countries: "/api/countries",
  banks:"/api/banks",
  tenants:"/api/tenants",
  home:"/api/home/index",
  cashflowstatement: "/api/cashflow/index",
  budgets: "/api/budgets",
  budgetEstimates: "/api/budgets/coa",
  trialbalance: "/api/trialbalance/index",
  incomestatement: "/api/incomestatement/index",
  setSchoolFees: "/api/school/fees/payable",
  recordFees: "/api/school/fees/record",
  viewFees: "/api/school/fees/index",
  feesAdvances: "/api/school/fees/advance",
  feesDefaulters: "/api/school/fees/defaulters",
  getStudentCategories: "/api/school/studentcategories",
  getStudentsList: "/api/school/students",
  students:"/api/school/students",
  schoolStudentCategories:"/api/school/studentcategories",
  promoteStudents:"/api/student/promote",
  getSchoolFeesDefaults: "/api/school/fees/defaults",
  requistionsDefaults: "/api/requisitions/defaults",
  requistions: "/api/requisitions",
  generaljournals: "/api/generaljournals",
  uploaddata: "/api/uploaddata"
}