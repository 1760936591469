import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";
import QRCode from "react-qr-code";
import XModal from "../../components/common/XModal";
import InvoiceList from "./invoices"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateForm from "../../components/forms/CreateForm";
import { EmailFields } from "../../fields/EmailFields";
import { EmailSchema } from "../../schemas/EmailSchema";
import { SalesAttachmentFields } from "../../fields/SalesAttachmentFields";
import { SaleUpdateFields } from "../../fields/SaleUpdateFields";

const SaleDetail = () => {
  const [sale, setSale] = useState<any>({});
  const [email, setEmail]= useState<any>({});
  const [attachment, setAttachment]= useState<any>({sale_id: useParams().id});
  const [saleupdate, setSaleupdate]= useState<any>({sale_id: useParams().id});
  const [loading, setLoading] = useState<boolean>(true);
  const [showInvoices, setShowInvoices] = useState<boolean>(false);
  const [showAttachment, setShowAttachment] = useState<boolean>(false);
  const [showSaleupdate, setShowSaleupdate] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.salesOrders + "/" + id)
      .then((response: AxiosResponse) => {
        setSale(response.data);
        const emailMessage: any = {
          sender: tenant.name,
          senderemail: "no-reply@eygo.biz",
          subject: "Sale #" + response.data.id,
          destination: response.data.company.contactemail,
          message: `Dear ${response.data.company.name},\r\nPlease find attached a copy of your sale..\r\n\r\nIn case you need further assistance or clarification, feel free to contact us`
        }
        setEmail(emailMessage);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const fetchPdf = (id: string) => {
    toast
    .promise(request
        .get(Endpoints.salesOrders + '/' + id, { params: { pdf: true } }, "arraybuffer"),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], {type: type})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `sale-${+ new Date()}.pdf`
          link.click()
        });
  }

  const handleSendEmail = (values: any) => {
    toast
    .promise(request
        .create(Endpoints.emailInvoice + '/' + sale.id, values),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return data ? data.data : 'Email sent successfully';
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }).then((response: AxiosResponse) => {
          setShowEmail(false);
        });
  }

  const handleSendAttachment = (values: any) => {
    window.location.reload();
  }

  const handleOption = async (id: string, type: string) => {
    if (type == "edit") {
      history.push("/sales/new?id=" + id);
    } else if(type == "pdf") {
      fetchPdf(id);
    } else if (type == "sale invoice") {
      history.push(
        "/transactions/sales/new?saleId=" + id + "&invtype=sale"
      );
    } else if (type == "purchase invoice") {
      history.push(
        "/transactions/sales/new?saleId=" + id + "&invtype=purchase"
      );
    } else if (type == "list invoice"){
      setShowInvoices(true);
    } else if (type == "email"){
      setShowEmail(true);
    } else if (type == "attachment"){
      setShowAttachment(true);
    } else if (type == "update"){
      setShowSaleupdate(true);
    }
  };

  const computeAmounts = (item: any) => {
    let tax = 0; let netAmount = 0;
    netAmount = item.amount;
    return [tax,netAmount];
  }

  let totalTax = 0;
  let totalNetAmount = 0;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Sales Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Sales" breadcrumbItem="Sales Detail" />
          <XModal title={"Invoices"}
                  width={"lg"}
                  open={showInvoices}
                  toggle={() => setShowInvoices(!showInvoices)}
                  onClose={() => setShowInvoices(false)}>

            <InvoiceList invoices={sale.invoices} onClose={() => setShowInvoices(false)} />

          </XModal>

          <XModal title={"Send sale on email"}
                  width={"lg"}
                  open={showEmail}
                  toggle={() => setShowEmail(!showEmail)}
                  onClose={() => setShowEmail(false)}>

            <CreateForm
              fields={EmailFields}
              initialValues={email}
              submitButtonLabel={"Send Email"}
              validationSchema={EmailSchema}
              debug={false}
              handleUpdate={handleSendEmail}
              onClose={() => setShowEmail(false)} />

          </XModal>

          <XModal title={"Add attachment"}
                  width={"lg"}
                  open={showAttachment}
                  toggle={() => setShowAttachment(!showAttachment)}
                  onClose={() => setShowAttachment(false)}>

            <CreateForm
              fields={SalesAttachmentFields}
              initialValues={attachment}
              submitEndpoint={attachment.id ? Endpoints.saleattachments + "/" + attachment.id : Endpoints.saleattachments}
              debug={false}
              formData={true}
              submitButtonLabel={"Save"}
              handleUpdate={handleSendAttachment}
              onClose={() => setShowAttachment(false)} />

          </XModal>

          <XModal title={"Add Update"}
                  width={"lg"}
                  open={showSaleupdate}
                  toggle={() => setShowSaleupdate(!showSaleupdate)}
                  onClose={() => setShowSaleupdate(false)}>

            <CreateForm
              fields={SaleUpdateFields}
              initialValues={saleupdate}
              submitEndpoint={saleupdate.id ? Endpoints.saleupdates + "/" + attachment.id : Endpoints.saleupdates}
              debug={false}
              formData={true}
              submitButtonLabel={"Save"}
              handleUpdate={handleSendAttachment}
              onClose={() => setShowSaleupdate(false)} />

          </XModal>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Sales Order{" "}
                    {sale.efris_error_message &&
                    sale.efris_error_message.length > 0
                      ? `[${sale.efris_error_message}]`
                      : null}
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(sale.id, "pdf");
                        }}
                      >
                        PDF Version
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(sale.id, "email");
                        }}
                      >
                        Send Via Email
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(sale.id, "edit");
                        }}
                      >
                        Edit Order
                      </DropdownItem>
                      <DropdownItem
                          to="#"
                          onClick={() => {
                            handleOption(sale.id, "list invoice");
                          }}
                        >
                          View Invoices
                      </DropdownItem>
                      <DropdownItem
                          to="#"
                          onClick={() => {
                            handleOption(sale.id, "attachment");
                          }}
                        >
                          Add attachment
                      </DropdownItem>
                      <DropdownItem
                          to="#"
                          onClick={() => {
                            handleOption(sale.id, "update");
                          }}
                        >
                          Add update
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(sale.id, "sale invoice");
                        }}
                      >
                        Invoice Client
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(sale.id, "purchase invoice");
                        }}
                      >
                        Record Expense
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading sales order. Please wait." />}
                  {!loading && (
                    <>
                      <Row>
                        <Col sm={3}>
                          <div className="sale-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Order For: </h5>
                            <h5 className="font-size-15 mb-2">
                              {sale.company.name} - {sale.company.tin}
                            </h5>
                            <p className="mb-1">
                              {sale.company.street} {sale.company.city}
                            </p>
                            <p className="mb-1">
                              {sale.company.contactemail}
                            </p>
                            <p>{sale.company.contractphone}</p>
                          </div>
                        </Col>

                        <Col sm={4}>
                          
                        </Col>

                        <Col sm={2}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">
                                Order No:{" "}
                              </h5>
                              <p>#{sale.id}</p>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">
                                Order Date:{" "}
                              </h5>
                              <p>{formattedDate(sale.created)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th>Price ({sale.currency.code})</th>
                                <th>Quantity</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Total ({sale.currency.code})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sale.saleitems.map((item: any, index: number) => {
                                const amounts = computeAmounts(item);
                                totalTax += amounts[0];
                                totalNetAmount += amounts[1];
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                          item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                            ? item.product.name
                                            : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td> {amountToCurrency(item.rate ? item.rate : 0)}</td>
                                    <td>{amountToCurrency(item.quantity ? item.quantity : 0)}</td>
                                    <td className="text-end">
                                      {amountToCurrency(item.amount ? item.amount : 0)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={4}
                                  className="text-end"
                                >
                                  Sub Total
                                </th>
                                <td className="text-end">
                                  {sale.currency.code}{" "}
                                  {amountToCurrency(totalNetAmount)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr/>
                      {sale.remarks}
                      <hr/>
                      <Row>
                        <Col lg={8}>
                            <h5>Updates</h5>
                            {sale.updates.map((item: any) => {
                              return (
                                <div key={item.id}>
                                  <b>{item.created_at}</b>
                                  <div>
                                  {item.remark}
                                  </div>
                                  <hr/>
                                </div>
                              )
                            })}
                        </Col>
                        <Col lg={4}>
                        <h5>Attachments</h5>
                        {sale.saleattachments.map((item: any) => {
                              return (
                                <div key={item.id}>
                                  <a href={fullUrl(item.attachment_path)}>{item.attachment_file_name}</a>
                                  <hr/>
                                </div>
                              )
                            })}
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SaleDetail;
