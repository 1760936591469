import React, { useState } from "react";

import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { amountToCurrency, formattedDate } from "../../utils/Helpers";

import UserChart from "../AllCharts/apex/UserChart";

const User = (props: any) => {
  const { data } = props;
    console.log(data);
  const getGraphData = () => {
    return {
      xData: Object.keys(data.sales_trend_data).map((v: any) => formattedDate(v)),
      yData: Object.values(data.sales_trend_data),
    };
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-transparent">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0">Sales trend</h5>
            </div>
          </div>
        </CardHeader>
        <CardBody className="border-bottom py-3">
          <Row className="gx-lg-5">
            <div className="col-md-auto">
              <div>
                <p className="text-muted mb-2">Total Sales</p>
                <h4 className="mb-0">
                  {amountToCurrency(data.total_sales_invoices)}{" "}
                  <span className="text-muted font-size-12 fw-normal ms-2">
                    <i className="uil uil-arrow-up-right text-success ms-1"></i>
                  </span>
                </h4>
              </div>
            </div>
            <div className="col-md align-self-end">
              <div className="text-md-end mt-4 mt-md-0">
                <ul className="list-inline main-chart mb-0">
                  <li className="list-inline-item chart-border-left me-0 border-0">
                    <h4 className="text-primary my-1">
                      {amountToCurrency(data.total_receipts)}
                      <span className="text-muted d-inline-block fw-normal font-size-13 ms-2">
                        Cash sales
                      </span>
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </CardBody>
        <div>
          <div id="chart-area" className="apex-charts" dir="ltr">
            <UserChart data = {getGraphData()} />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default User;
