import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";
import QRCode from "react-qr-code";
import XModal from "../../components/common/XModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateForm from "../../components/forms/CreateForm";
import { EmailFields } from "../../fields/EmailFields";
import { EmailSchema } from "../../schemas/EmailSchema";

const AccountDetail = () => {
  const [account, setAccount] = useState<any>({});
  const [email, setEmail]= useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMemos, setShowMemos] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.accounts + "/" + id)
      .then((response: AxiosResponse) => {
        setAccount(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  const round = (num: any) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  const computeAmounts = (item: any) => {
    let tax = 0; let netAmount = 0;
    if (item.tax) {
      tax = item.amount - (item.amount/(1 + (item.tax.taxpct / 100)));
      tax = round(tax);
      netAmount = item.amount - tax
    }
    return [tax,netAmount];
  }

  let totalTax = 0;
  let totalNetAmount = 0;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Account Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Accounts" breadcrumbItem="Account Detail" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Account{" "}
                    {account.efris_error_message &&
                    account.efris_error_message.length > 0
                      ? `[${account.efris_error_message}]`
                      : null}
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          
                        }}
                      >
                        PDF Version
                      </DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading account. Please wait." />}
                  {!loading && (
                    <>
                      <Row>
                        <Col sm={3}>
                          <div className="account-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Account For: </h5>
                            <h5 className="font-size-15 mb-2">
                              {account.company.name} - {account.company.tin}
                            </h5>
                            <p className="mb-1">
                              {account.company.street} {account.company.city}
                            </p>
                            <p className="mb-1">
                              {account.company.contactemail}
                            </p>
                            <p>{account.company.contractphone}</p>
                          </div>
                        </Col>

                        <Col sm={4}>
                          {account.efris_id ? (
                            <Row>
                              <Col sm={4}>
                                <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                                  <QRCode
                                  size={256}
                                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                  value={account.efris_code}
                                  viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <h5>URA EFRIS</h5>
                                <div>
                                  <b>FDN:</b> {account.efris_account_no}
                                </div>
                                <div>
                                  <b>Device No:</b> {account.efris_device_no}
                                </div>
                                <div>
                                  <b>Verification Code:</b> {account.efris_verification_code}
                                </div>
                                <div>
                                  <b>Date:</b> {account.efris_date_time}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>

                        <Col sm={2}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">
                                Account No:{" "}
                              </h5>
                              <p>#{account.id}</p>
                              <div>
                                {account.balance == 0 ? (
                                  <span className="badge bg-success font-size-12 ms-2">
                                    PAID
                                  </span>
                                ) : (
                                  <span className="badge bg-danger font-size-12 ms-2">
                                    NOT PAID
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">
                                Account Date:{" "}
                              </h5>
                              <p>{formattedDate(account.created)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th>Price ({account.currency.code})</th>
                                <th>Quantity</th>
                                <th className="text-end">Tax ({account.currency.code})</th>
                                <th className="text-end">Net Amount ({account.currency.code})</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Total ({account.currency.code})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {account.items.map((item: any, index: number) => {
                                const amounts = computeAmounts(item);
                                totalTax += amounts[0];
                                totalNetAmount += amounts[1];
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                          item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                            ? item.product.name
                                            : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td> {amountToCurrency(item.rate)}</td>
                                    <td>{amountToCurrency(item.quantity)}</td>
                                    <td className="text-end">{amountToCurrency(amounts[0])}</td>
                                    <td className="text-end">{amountToCurrency(amounts[1])}</td>
                                    <td className="text-end">
                                      {amountToCurrency(item.amount)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="text-end"
                                >
                                  Sub Total
                                </th>
                                <td className="text-end">
                                  {account.currency.code}{" "}
                                  {amountToCurrency(totalNetAmount)}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="border-0 text-end"
                                >
                                  Discount :{" "}
                                </th>
                                <td className="border-0 text-end">
                                  {account.currency.code}{" "}
                                  {amountToCurrency(
                                    (account.discount * account.tot) / 100
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="border-0 text-end"
                                >
                                  Tax
                                </th>
                                  <td className="border-0 text-end">{account.currency.code}{" "} {amountToCurrency(totalTax)}</td>
                              </tr>

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="border-0 text-end"
                                >
                                  Total
                                </th>
                                <td className="border-0 text-end">
                                  <h4 className="m-0 fw-semibold">
                                    {account.currency.code}{" "}
                                    {amountToCurrency(
                                      account.tot -
                                        (account.discount * account.tot) / 100
                                    )}
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountDetail;
