import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/common/Breadcrumb";
import { Loading } from "../../components/common/Loading";

import {
  Button,
  Card, CardBody, Col,
  Container,
  Row
} from "reactstrap";

import { FieldArray, Form, Formik} from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XTextArea } from "../../components/common/inputs/XTextArea";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Endpoints } from "../../utils/Endpoints";
import { useFocus } from "../../hooks/useFocus";
import { ApiCore } from "../../helpers/api_helper";
import { amountToCurrency, currentDate, dateToDbFormat } from "../../utils/Helpers";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { AxiosError, AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import { SupplierFields } from "../../fields/SupplierFields";
import { SupplierSchema } from "../../schemas/SupplierSchema";
import CreateForm from "../../components/forms/CreateForm";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ISupplier } from "../../interfaces/suppliers/ISupplier";
import { ProductFields } from "../../fields/ProductFields";
import { ProductSchema } from "../../schemas/ProductSchema";
import { IProduct } from "../../interfaces/products/IProduct";
import { XSearchableSelect } from "../../components/common/inputs/XSearchableSelect";

const DataForm = () => {

  const [defaults, setDefaults] = useState<any>({});
  const [companies, setCompanies] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStockrecord, setLoadingStockrecord] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);
  const category = params.get("category") ? params.get("category") : "sale";
  const [showSupplierDialog, setShowSupplierDialog] = useState<boolean>(false);
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);

  const [stockrecord, setStockrecord] = useState<any>(null);

  const history = useHistory();

  const request = new ApiCore();

  const stockrecordId = params.get("id") ? params.get("id") : null;

  if (stockrecordId) {
    useEffect(() => {
      request
        .get(Endpoints.stockrecords + "/" + stockrecordId)
        .then((response: AxiosResponse) => {
          setStockrecord(response.data);     
          setLoadingStockrecord(false);    
        })
        .catch(error => {
          console.error(error);
        });
    }, []);
  }

  useEffect(() => {
    request
      .get(Endpoints.defaults)
      .then((response: AxiosResponse) => {
        setDefaults(response.data);
        setLoading(false);
        if (!stockrecordId) {
          setLoadingStockrecord(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {

    request.get(Endpoints.companies, {})
      .then((response: AxiosResponse) => {
        const { companies, totalRecords } = response.data;
        let options: any = []
        options = companies.map((data: any) => ({
          value: data.id,
          label: data.name || data.taxid,
          data: JSON.stringify(data)
        }))
        setCompanies(options);
        initialValues.company_id = "";

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {

    request.get(Endpoints.products, {})
      .then((response: AxiosResponse) => {
        const { products, totalRecords } = response.data;
        let options: any = []
        options = products.map((data: any) => ({
          value: data.id,
          label: data.name || data.taxid,
          data: JSON.stringify(data)
        }))
        setProducts(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const categoryList: ISelectOption[] = [
    { value: 'Stock In', text: 'Stock In' },
    { value: 'Stock Out', text: 'Stock Out' },
    { value: 'Stock Transfer', text: 'Stock Transfer' }
  ]

  let initialValues: any = {}
  if (stockrecord) {
    initialValues = {
      company_id: stockrecord.company_id ? stockrecord.company_id : "",
      flocation: stockrecord.flocation ? stockrecord.flocation : "",
      tlocation: stockrecord.tlocation ? stockrecord.tlocation : "",
      category: stockrecord.category,
      date: stockrecord.date ? stockrecord.date : "",
      items: stockrecord.items.map((item: any) => {
        if (!item.tax_id){
          item.tax_id = "";
        }
        return item;
      })
    }
  } else {
    initialValues = {
      company_id: "",
      flocation: "",
      tlocation: "",
      efris: 0,
      category: category,
      date: currentDate(),
      items: [
        {
          product_id: "",
          particulars: "",
          amount: 0.00,
          quantity: 1,
          expiry: "",
          batchno: "",
          tax_id: "",
        }
      ]
    }
  }

  const handleClickNewCompany = () => {
    setShowSupplierDialog(true);
  };

  const handleClickNewProduct = (index: number) => {
    setSelectedIndex(index);
    setShowProductDialog(true);
  };

  const handleCreateCompany = (supplier: ISupplier, f: any) => {
    const updatedCompanies = [...companies, {
      value: supplier.id,
      label: supplier.name,
      data: JSON.stringify(supplier)
    }]
    setCompanies(updatedCompanies);
    f('company_id',supplier.id);
  };

  const handleCreateProduct = (product: IProduct, f: any, values: any) => {
    const updatedProducts = [...products, {
      value: product.id,
      label: product.name,
      data: JSON.stringify(product)
    }];

    setProducts(updatedProducts);
    f(`items.${selectedIndex}.product_id`, product.id)

    f(`items.${selectedIndex}.quantity`, 1)
    f(`items.${selectedIndex}.rate`, product.sellingprice)
    f(`items.${selectedIndex}.particulars`, product.name)
    if (values.category == "sale") {
      f(`items.${selectedIndex}.craccountid`, product.salesaccount);
    } else {
      f(`items.${selectedIndex}.craccountid`, product.purchaseaccount);
    }
  };

  const handleSubmit = (values: any) => {
    const request_body = values;
    request_body.stockrecorditems_attributes = request_body.items;
    if (stockrecordId) {
      request_body.id = stockrecordId;
      request.update(Endpoints.stockrecords + "/" + stockrecordId, request_body)
        .then((response: AxiosResponse) => {
          handleSubmitResponse(response.data);
        }).catch((error: AxiosError) => {
          console.log(error)
        })
    } else {
      request.create(Endpoints.stockrecords, request_body)
        .then((response: AxiosResponse) => {
          handleSubmitResponse(response.data);
        }).catch((error: AxiosError) => {
          console.log(error)
        })
    }
  };

  const handleSubmitResponse = (data: any) => {
    if (data.id) {
      history.push('/inventory/stocks/' + data.id)
    } else {
      alert('Stock updated');
    }
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stock Record | Treda</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Transactions" breadcrumbItem="Stock Record" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {(loading || loadingStockrecord) && <Loading label="Loading stock record. Please wait." />}
                  {(!loading && !loadingStockrecord) && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}>
                        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                          <>
                          <XModal title={"Add a supplier"}
                            width={"lg"}
                            open={showSupplierDialog}
                            toggle={() => setShowSupplierDialog(!showSupplierDialog)}
                            onClose={() => setShowSupplierDialog(false)}>

                            <CreateForm
                              fields={SupplierFields}
                              initialValues={initialValues}
                              enableReinitialize={true}
                              submitEndpoint={Endpoints.companies}
                              submitButtonLabel={"Create customer/supplier"}
                              validationSchema={SupplierSchema}
                              debug={false}
                              handleUpdate={(supplier: ISupplier) => {
                                handleCreateCompany(supplier, setFieldValue);
                              }}
                              onClose={() => setShowSupplierDialog(false)} />

                          </XModal>
                          <XModal title={"Add a product"}
                                width={"lg"}
                                open={showProductDialog}
                                toggle={() => setShowProductDialog(!showProductDialog)}
                                onClose={() => setShowProductDialog(false)}>

                            <CreateForm
                              fields={ProductFields}
                              initialValues={initialValues}
                              submitEndpoint={Endpoints.products}
                              submitButtonLabel={"Create Product"}
                              validationSchema={ProductSchema}
                              debug={false}
                              handleUpdate={(product: IProduct) => {
                                handleCreateProduct(product, setFieldValue, values);
                              }}
                              onClose={() => setShowProductDialog(false)} />

                          </XModal>
                          <Form>
                            <Row>
                              <Col lg={2}>
                                <XSelect key="category" id="category"
                                  name={"category"}
                                  options={categoryList}
                                  label={"Type"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                              <Col lg={2}>
                                <XDatePickerField key="date" id="date"
                                  name={"date"}
                                  label={"Date"}
                                  touched={touched}
                                  onChange={(f: any, v: Date) => {
                                    setFieldValue(f, dateToDbFormat(v));
                                  }}
                                  value={values.date}
                                  errors={errors} />
                              </Col>
                              <Col lg={2}>
                                <XSelect
                                  key="flocation"
                                  id="flocation"
                                  name={"flocation"}
                                  dataSrc={Endpoints.locations}
                                  dataType={"locations"}
                                  label={"Source Store"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                              <Col lg={2}>
                                <XSelect
                                  key="tlocation"
                                  id="tlocation"
                                  name={"tlocation"}
                                  dataSrc={Endpoints.locations}
                                  dataType={"locations"}
                                  label={"Destination Store"}
                                  touched={touched}
                                  errors={errors} />
                              </Col>
                              <Col lg={2}>
                                <XSearchableSelect
                                  key="company_id"
                                  id="company_id"
                                  value={values.company_id}
                                  name={"company_id"}
                                  options={companies}
                                  label={"Supplier"}
                                  handleNewClick={e => {
                                    handleClickNewCompany();
                                  }}
                                  onChange={(value: any) => {
                                    setFieldValue("company_id", value.value);
                                  }}
                                  touched={touched}
                                  errors={errors}
                                />
                              </Col>
                              <Col lg={2}>
                                <XCheckbox
                                  key="efris"
                                  id="efris"
                                  name={"efris"}
                                  label={"Update Efris"}
                                  value="1"
                                  includeTopPadding={true}
                                  onChange={setFieldValue}
                                  size={{}} />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <div className={"mt-4 border-top border-bottom pt-2 bg-light"}>
                                  <Row>
                                    <Col lg={2} className="px-4"><h6>Item</h6></Col>
                                    <Col lg={1}><h6>Qty</h6></Col>
                                    <Col lg={1}><h6>Rate</h6></Col>
                                    <Col lg={2}><h6>Particulars</h6></Col>
                                    <Col lg={1}><h6>Tax</h6></Col>
                                    <Col lg={2}><h6>Batch No</h6></Col>
                                    <Col lg={2}><h6>Expiry</h6></Col>
                                    <Col lg={1}></Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={12}>
                                <FieldArray name={"items"} render={arrayHelpers => (
                                  <div className={"py-3"}>
                                    {values.items &&
                                      values.items.map((item: any, index: number) => (
                                        <Row key={index}>
                                          <Col lg={2}>
                                            <XSearchableSelect
                                              onChange={(field: any) => {
                                                const productId = field.value;
                                                if (productId == -1) {
                                                  handleClickNewProduct(index);
                                                } else {
                                                  setFieldValue(`items.${index}.product_id`,productId);
                                                  const data = JSON.parse(field.data);

                                                  setFieldValue(`items.${index}.quantity`, 1)
                                                  setFieldValue(`items.${index}.amount`, data.sellingprice)
                                                  setFieldValue(`items.${index}.particulars`, data.name)
                                                }
                                              }}
                                              allowAddNew={true}
                                              options={products}
                                              id={`items.${index}.product_id`}
                                              name={`items.${index}.product_id`}
                                              value={values.items[index].product_id}
                                              touched={touched}
                                            />
                                          </Col>
                                          <Col lg={1}>
                                            <XTextBox
                                              id={`items.${index}.quantity`}
                                              type={"text"}
                                              name={`items.${index}.quantity`}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={1}>
                                            <XTextBox
                                              id={`items.${index}.amount`}
                                              type={"text"}
                                              name={`items.${index}.amount`}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={2}>
                                            <XTextBox
                                              id={`items.${index}.particulars`}
                                              type={"text"}
                                              name={`items.${index}.particulars`}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={1}>
                                            <XSelect
                                              dataType={"taxes"}
                                              dataSrc={Endpoints.taxes}
                                              id={`items.${index}.tax_id`}
                                              name={`items.${index}.tax_id`}
                                              touched={touched} />
                                          </Col>
                                          <Col lg={2}>
                                          <XTextBox
                                              id={`items.${index}.batchno`}
                                              type={"text"}
                                              name={`items.${index}.batchno`}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={2}>
                                            <XDatePickerField
                                              id={`items.${index}.expiry`}
                                              type={"text"}
                                              name={`items.${index}.expiry`}
                                              value={values.items[index].expiry}
                                              onChange={(f: any, v: Date) => {
                                                setFieldValue(f, dateToDbFormat(v));
                                              }}
                                              touched={touched}
                                              errors={errors} />
                                          </Col>
                                          <Col lg={1}>
                                            <Button className={"btn btn-soft-danger"} to={"#"}>
                                              <i className="fa fa-times"
                                                onClick={() => arrayHelpers.remove(index)}></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      )
                                      )}
                                    <Button
                                      className={"btn btn-soft-primary"}
                                      type="button"
                                      onClick={() => arrayHelpers.push({
                                        product_id: "",
                                        particulars: "",
                                        amount: "",
                                        quantity: "",
                                        expiry: "",
                                        batchno: "",
                                        tax_id: ""
                                      })}>
                                      <i className={"mdi mdi-plus"}></i> Add Item
                                    </Button>

                                  </div>
                                )} />
                              </Col>

                            </Row>

                            <Row>
                              <XTextArea id="remarks" label="Remarks" name="remarks" size={{ lg: 12 }} touched={touched} />
                            </Row>

                            <div className="modal-footer">
                              <Button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                Save
                              </Button>
                            </div>

                          </Form>
                          </>

                        )}


                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default DataForm;