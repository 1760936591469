import { IField } from "../interfaces/inputs/IField";

const productTypes = [
  {value: 'service', text: 'Service'},
  {value: 'consumable', text: 'Consumable'},
  {value: 'stockable', text: 'Stockable'},
]

export const AccountFields: IField[] = [
  { name: "name", type: "text", label: "Account Name", size: {lg: 12}, position: 1 },
  { name: "code", type: "text", label: "Code", size: {lg: 6}, position: 2 },
  { name: "special_category", type: "text", label: "Category", size: {lg: 6}, position: 3 }
].sort((a: IField, b: IField) => a.position - b.position);