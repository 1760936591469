import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Endpoints } from "../../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchReport from "./search";
import { invoiceEndDate, invoiceStartDate } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { values } from "lodash";

const TrialBalance = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  const searchTrialBalance = (values: any) => {
    if (values.pdf && values.pdf == 1) {
      fetchFile(values)
    } else {
      fetchHtml(values)
    }
  };

  const fetchFile = (values: any) => {
    toast
      .promise(request
        .get(Endpoints.trialbalance, { params: { sdate: values.sdate, edate: values.edate, pdf: 1 } }, "arraybuffer"), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `trialbalance-${+ new Date()}.pdf`
        link.click()
      });
  }

  const fetchHtml = (values: any) => {
    toast
      .promise(request.get(Endpoints.trialbalance, { params: { sdate: values.sdate, edate: values.edate } }), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const { accounts, totalRecords } = response.data;
        setAccounts(accounts);
        setTotalRecords(totalRecords);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const request = new ApiCore();

  const pageOptions = {
    sizePerPage: 25,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Trial Balance | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Reports" breadcrumbItem="Trialbalance" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Trial Balance</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/transactions/sales/new");
                        }}
                      >
                        Add Invoice
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <SearchReport
                        sdate={invoiceStartDate()}
                        edate={invoiceEndDate()}
                        handleSearch={(values: any) => {
                          searchTrialBalance(values);
                        }}
                      />
                    </Col>
                  </Row>
                  <table className="table">
                    <thead>
                      <tr>
                        <th><b>Ref #</b></th>
                        <th><b>Account Title</b></th>
                        <th className="text-center"><b>Dr</b></th>
                        <th className="text-center"><b>Cr</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts && accounts.map((item: any) => {
                        return (<><tr key={item.id}>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                          <td className="text-center">{amountToCurrency(item.dr)}</td>
                          <td className="text-center">{amountToCurrency(item.cr)}</td>
                        </tr>
                        </>)
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TrialBalance;
