import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/common/Breadcrumb";
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XTextArea } from "../../components/common/inputs/XTextArea";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Endpoints } from "../../utils/Endpoints";
import SaleSummary from  "../Sales/InvoiceSummary";
import { ApiCore } from "../../helpers/api_helper";
import { amountToCurrency, currentDate, round, taxCalculator } from "../../utils/Helpers";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";
import { AxiosError, AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import { SupplierFields } from "../../fields/SupplierFields";
import { SupplierSchema } from "../../schemas/SupplierSchema";
import CreateForm from "../../components/forms/CreateForm";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ISupplier } from "../../interfaces/suppliers/ISupplier";
import { ProductFields } from "../../fields/ProductFields";
import { ProductSchema } from "../../schemas/ProductSchema";
import { IProduct } from "../../interfaces/products/IProduct";
import { XSearchableSelect } from "../../components/common/inputs/XSearchableSelect";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const NewSale = () => {
  const [defaults, setDefaults] = useState<any>({});
  const [companies, setCompanies] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSale, setLoadingSale] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";
  const [showSupplierDialog, setShowSupplierDialog] = useState<boolean>(false);
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);

  const [sale, setSale] = useState<any>(null);

  const history = useHistory();

  const request = new ApiCore();

  const saleId = params.get("id") ? params.get("id") : null;

  if (saleId) {
    useEffect(() => {
      request
        .get(Endpoints.salesOrders + "/" + saleId)
        .then((response: AxiosResponse) => {
          setSale(response.data);
          setLoadingSale(false);
        })
        .catch(error => {
          console.error(error);
        });
    }, []);
  }

  useEffect(() => {
    request
      .get(Endpoints.defaults)
      .then((response: AxiosResponse) => {
        setDefaults(response.data);
        setLoading(false);
        if (!saleId) {
          setLoadingSale(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.companies, {})
      .then((response: AxiosResponse) => {
        let options: any = [];
        options = response.data.companies.map((data: any) => ({
          value: data.id,
          label: data.name || data.taxid,
          data: JSON.stringify(data),
        }));
        setCompanies(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.products, {})
      .then((response: AxiosResponse) => {
        const { products, totalRecords } = response.data;
        let options: any = [];
        options = products.map((data: any) => ({
          value: data.id,
          label: data.name || data.taxid,
          data: JSON.stringify(data),
        }));
        setProducts(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const statuses: ISelectOption[] = [
    { value: "pending", text: "Pending" },
    { value: "in progress", text: "In Progress" },
    { value: "completed", text: "Completed" },
    { value: "cancelled", text: "Cancelled" },
    { value: "blocked", text: "Blocked" },
    { value: "rejected", text: "Rejected" },
  ];

  let initialValues: any = {};
  if (sale) {
    initialValues = {
      company_id: sale.company_id ? sale.company_id : "",
      currency_id: sale.currency ? sale.currency.id : "",
      currency: sale.currency,
      status: sale.status,
      created: sale.created ? sale.created : "",
      rate: sale.rate ? sale.rate : 1,
      saleitems: sale.saleitems.map((item: any) => {
        if (!item.tax_id) {
          item.tax_id = "";
        }
        return item;
      }),
    };
  } else {
    initialValues = {
      company_id: "",
      currency_id: defaults.currency ? defaults.currency.id : "",
      currency: defaults.currency,
      status: 'pending',
      created: currentDate(),
      rate: defaults.currency ? defaults.currency.rate : 1,
      saleitems: [
        {
          product_id: "",
          particulars: "",
          rate: 0.0,
          quantity: 1,
          amount: 0.0,
        },
      ],
    };
  }

  const handleLineItemChange = (index: number) => (event: any) => {};

  const computeSubTotal = (items: [any]) => {
    const sum = items.reduce((accumulator, object) => {
      return accumulator + object.amount;
    }, 0);
    return sum;
  };

  const computeDiscount = (items: [any], discount: number) => {
    return (discount * computeSubTotal(items)) / 100;
  };

  const computeAmount = (index: number, item: any, setFieldValue: any) => {
    console.log(item);
    setFieldValue(`saleitems.${index}.amount`, item.rate * item.quantity);
  };

  const computeTax = (items: [any]) => {
    const sum = items.reduce((accumulator, object) => {
      return (
        accumulator + taxCalculator(object)
      );
    }, 0);
    return sum;
  };

  const handleClickNewCompany = () => {
    setShowSupplierDialog(true);
  };

  const handleClickNewProduct = (index: number) => {
    setSelectedIndex(index);
    setShowProductDialog(true);
  };

  const handleCreateCompany = (supplier: ISupplier, f: any) => {
    const updatedCompanies = [
      ...companies,
      {
        value: supplier.id,
        label: supplier.name,
        data: JSON.stringify(supplier),
      },
    ];
    setCompanies(updatedCompanies);
    f("company_id", supplier.id);
  };

  const handleCreateProduct = (product: IProduct, f: any, values: any) => {
    const updatedProducts = [
      ...products,
      {
        value: product.id,
        label: product.name,
        data: JSON.stringify(product),
      },
    ];

    setProducts(updatedProducts);
    f(`saleitems.${selectedIndex}.product_id`, product.id);

    f(`saleitems.${selectedIndex}.quantity`, 1);
    f(`saleitems.${selectedIndex}.rate`, product.sellingprice);
    f(`saleitems.${selectedIndex}.particulars`, product.name);
  };

  const handleSubmit = (values: any) => {
    const request_body = values;
    request_body.saleitems_attributes = request_body.saleitems;
    for (let i = 0; i < request_body.saleitems.length; i++) {
      delete request_body.saleitems[i].tax;
      delete request_body.saleitems[i].product;
    }
    
    if (saleId) {
      request_body.id = saleId;
      request
        .update(Endpoints.salesOrders + "/" + saleId, request_body)
        .then((response: AxiosResponse) => {
          history.push("/sales/" + response.data.id);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    } else {
      toast.promise(request.create(Endpoints.salesOrders, request_body), {
        pending: "Please wait..",
        error: {
          render({data}) {
            console.log(data);
            return data;
          }
        }
      }).then((response: AxiosResponse) => {
        history.push("/sales/" + response.data.id);
      });
    }
  };

  const getCurrencyCode = (currency: any) => {
    return currency ? currency.code : "UGX";
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Sale Order | Treda</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Transactions" breadcrumbItem="New Sale Order" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <ToastContainer />
                  {(loading || loadingSale) && (
                    <Loading label="Loading sales orders. Please wait." />
                  )}
                  {!loading && !loadingSale && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <XModal
                              title={"Add a customer"}
                              width={"lg"}
                              open={showSupplierDialog}
                              toggle={() =>
                                setShowSupplierDialog(!showSupplierDialog)
                              }
                              onClose={() => setShowSupplierDialog(false)}
                            >
                              <CreateForm
                                fields={SupplierFields}
                                initialValues={initialValues}
                                enableReinitialize={true}
                                submitEndpoint={Endpoints.companies}
                                submitButtonLabel={"save"}
                                validationSchema={SupplierSchema}
                                debug={false}
                                handleUpdate={(supplier: ISupplier) => {
                                  handleCreateCompany(supplier, setFieldValue);
                                }}
                                onClose={() => setShowSupplierDialog(false)}
                              />
                            </XModal>
                            <XModal
                              title={"Add a product"}
                              width={"lg"}
                              open={showProductDialog}
                              toggle={() =>
                                setShowProductDialog(!showProductDialog)
                              }
                              onClose={() => setShowProductDialog(false)}
                            >
                              <CreateForm
                                fields={ProductFields}
                                initialValues={initialValues}
                                submitEndpoint={Endpoints.products}
                                submitButtonLabel={"Create Product"}
                                validationSchema={ProductSchema}
                                debug={false}
                                handleUpdate={(product: IProduct) => {
                                  handleCreateProduct(
                                    product,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                onClose={() => setShowProductDialog(false)}
                              />
                            </XModal>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="sts"
                                    id="sts"
                                    name={"status"}
                                    options={statuses}
                                    label={"Status"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XDatePickerField
                                    key="created"
                                    id="created"
                                    name={"created"}
                                    label={"Date"}
                                    touched={touched}
                                    onChange={setFieldValue}
                                    value={values.created}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XDatePickerField
                                    key="expected_delivery_date"
                                    id="expected_delivery_date"
                                    name={"expected_delivery_date"}
                                    label={"Expected Delivery Date"}
                                    touched={touched}
                                    onChange={setFieldValue}
                                    value={values.expected_delivery_date}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XDatePickerField
                                    key="actual_delivery_date"
                                    id="actual_delivery_date"
                                    name={"actual_delivery_date"}
                                    label={"Actual Delivery Date"}
                                    touched={touched}
                                    onChange={setFieldValue}
                                    value={values.actual_delivery_date}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSearchableSelect
                                    key="company_id"
                                    id="company_id"
                                    value={values.company_id}
                                    name={"company_id"}
                                    options={companies}
                                    label={"Company"}
                                    handleNewClick={e => {
                                      handleClickNewCompany();
                                    }}
                                    onChange={(value: any) => {
                                      setFieldValue("company_id", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="currency_id"
                                    id="currency_id"
                                    name={"currency_id"}
                                    dataSrc={Endpoints.currencies}
                                    onChange={(field: any) => {
                                      const data = JSON.parse(
                                        field.target.selectedOptions[0].dataset
                                          .raw
                                      );

                                      setFieldValue(field.target.name, data.id);
                                      setFieldValue(`currency`, data);
                                      setFieldValue(`rate`, data.rate);
                                    }}
                                    dataType={"currencies"}
                                    label={"Currency"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    key="rate"
                                    id="rate"
                                    type={"text"}
                                    name={"rate"}
                                    label={"Rate"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={12}>
                                  <div
                                    className={
                                      "mt-4 border-top border-bottom pt-2 bg-light"
                                    }
                                  >
                                    <Row>
                                      <Col lg={3} className="px-4">
                                        <h6>Item</h6>
                                      </Col>
                                      <Col lg={1}>
                                        <h6>Qty</h6>
                                      </Col>
                                      <Col lg={2}>
                                        <h6>Rate</h6>
                                      </Col>
                                      <Col lg={3}>
                                        <h6>Particulars</h6>
                                      </Col>
                                      <Col lg={2}>
                                        <h6>Amount</h6>
                                      </Col>
                                      <Col lg={1}></Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col lg={12}>
                                  <FieldArray
                                    name={"saleitems"}
                                    render={arrayHelpers => (
                                      <div className={"py-3"}>
                                        {values.saleitems &&
                                          values.saleitems.map(
                                            (item: any, index: number) => (
                                              <Row key={index}>
                                                <Col lg={3}>
                                                  <XSearchableSelect
                                                    onChange={(field: any) => {
                                                      const productId = field.value;
                                                      if (productId == -1) {
                                                        handleClickNewProduct(index);
                                                      } else {
                                                        setFieldValue(`saleitems.${index}.product_id`,productId);
                                                        const data = JSON.parse(field.data);

                                                        setFieldValue(`saleitems.${index}.quantity`,1);
                                                        setFieldValue(`saleitems.${index}.rate`,data.sellingprice);
                                                        setFieldValue(`saleitems.${index}.particulars`,data.name);
                                                      }
                                                    }}
                                                    allowAddNew={true}
                                                    options={products}
                                                    id={`saleitems.${index}.product_id`}
                                                    name={`saleitems.${index}.product_id`}
                                                    value={values.saleitems[index].product_id}
                                                    touched={touched}
                                                  />
                                                </Col>
                                                <Col lg={1}>
                                                  <XTextBox
                                                    id={`saleitems.${index}.quantity`}
                                                    type={"text"}
                                                    handleBlur={event => {
                                                      computeAmount(
                                                        index,
                                                        item,
                                                        setFieldValue
                                                      );
                                                    }}
                                                    name={`saleitems.${index}.quantity`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                                <Col lg={2}>
                                                  <XTextBox
                                                    id={`saleitems.${index}.rate`}
                                                    type={"text"}
                                                    handleBlur={event => {
                                                      computeAmount(
                                                        index,
                                                        item,
                                                        setFieldValue
                                                      );
                                                    }}
                                                    name={`saleitems.${index}.rate`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                                <Col lg={3}>
                                                  <XTextBox
                                                    id={`saleitems.${index}.particulars`}
                                                    type={"text"}
                                                    name={`saleitems.${index}.particulars`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                                <Col lg={2}>
                                                  <XTextBox
                                                    id={`saleitems.${index}.amount`}
                                                    type={"text"}
                                                    onChange={handleLineItemChange(
                                                      index
                                                    )}
                                                    name={`saleitems.${index}.amount`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                                <Col lg={1}>
                                                  <Button
                                                    className={
                                                      "btn btn-soft-danger"
                                                    }
                                                    to={"#"}
                                                  >
                                                    <i
                                                      className="fa fa-times"
                                                      onClick={() =>
                                                        arrayHelpers.remove(
                                                          index
                                                        )
                                                      }
                                                    ></i>
                                                  </Button>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        <Button
                                          className={"btn btn-soft-primary"}
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              product_id: "",
                                              particulars: "",
                                              rate: "",
                                              quantity: "",
                                              amount: "",
                                            })
                                          }
                                        >
                                          <i className={"mdi mdi-plus"}></i> Add
                                          Item
                                        </Button>
                                      </div>
                                    )}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <XTextArea
                                  id="remarks"
                                  label="Remarks"
                                  name="remarks"
                                  size={{ lg: 12 }}
                                  touched={touched}
                                />
                              </Row>

                              <Row>
                                <Col lg={3}>
                                  <SaleSummary
                                    label={"Sub total"}
                                    icon="mdi mdi-calculator"
                                    value={
                                      getCurrencyCode(values.currency) +
                                      " " +
                                      amountToCurrency(
                                        computeSubTotal(values.saleitems)
                                      )
                                    }
                                  />
                                </Col>
                              </Row>

                              <div className="modal-footer">
                                <Button
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Save
                                </Button>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewSale;
