import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";

export const SupplierFields: IField[] = [
  { name: "name", type: "text", label: "Name", icon: 'fa-user', size: {lg: 6}, position: 1 },
  { name: "tin", type: "text", label: "TIN", icon: 'fa-cogs', size: {lg: 6}, position: 2 },
  { name: "city", type: "text", label: "City", icon: 'fa-map-pin', size: {lg: 6}, position: 3 },
  { name: "country_id", type: "select", label: "Country", icon: 'fa-map', dataType: "countries", dataSrc: Endpoints.countries, size: {lg: 6}, position: 3 },
  { name: "street", type: "text", label: "Street", icon: 'fa-road', size: {lg: 12}, position: 4 },
  { name: "phone", type: "text", label: "Office Telephone", icon: 'fa-phone-alt', size: {lg: 6}, position: 5 },
  { name: "mobile", type: "text", label: "Mobile Telephone", icon: 'fa-phone-alt', size: {lg: 6}, position: 6 },
  { name: "website", type: "text", label: "Website", icon: 'fa-globe', size: {lg: 12}, position: 8 },
  { name: "contactname", type: "text", label: "Contact Person", icon: 'fa-user-alt', size: {lg: 12}, position: 9 },
  { name: "contractphone", type: "text", label: "Contact Telephone", icon: 'fa-phone', size: {lg: 6}, position: 10 },
  { name: "contactemail", type: "text", label: "Contact Email", icon: 'fa-at', size: {lg: 6}, position: 11 },
  { name: "active", type: "checkbox" , position: 15, label: "Active", size: {lg: 13} },
].sort((a: IField, b: IField) => a.position - b.position);