import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

//import Breadcrumbs
import Breadcrumbs from "../../components/common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";

import User from "./User";
import Widget from "./Widget";
import VisitorSource from "./VisitorSource";
import TrafficSource from "./TrafficSource";
import Channels from "./Channels";
import { AxiosResponse } from "axios";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import { amountToCurrency } from "../../utils/Helpers";

const Dashboard = () => {
    const request = new ApiCore();
    const [loading, setLoading] = useState<boolean>(true);
    const [dashboard, setDashboard] = useState<any>({});

    useEffect(() => {

        request.get(Endpoints.home, {})
          .then((response: AxiosResponse) => {
            setDashboard(response.data);
            setLoading(false);
    
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);

    const getTopProducts = (type: string) => {
        const pdtData = (type == "sold") ? dashboard.top_products_sold : dashboard.top_products_bought;
         
        const sum = pdtData.reduce((accumulator: number, object: any) => {
            return accumulator + object[1];
          }, 0);
        const products: { product: any; amount: any; percentage: number; }[] = [];
        pdtData.forEach((element: any) => {
           products.push({
               product: element[0],
               amount: amountToCurrency(element[1]),
               percentage: Math.round((element[1] / sum) * 100)
           }) 
        });
        return products;
    };
      
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Home | Treda - React Admin &amp; Dashboard Template</title>
                </MetaTags>

                <Container fluid>
                {loading && <Loading label="Please wait..." />}
                {!loading &&
                    <>
                    <Breadcrumbs title="Dashboards" breadcrumbItem="Home" />

                    <Row>
                        <Col className="col-xxl-9" lg={8}>
                            <User data={dashboard} />
                        </Col>
                        <Col className="col-xxl-3" lg={4}>
                            <Widget data={dashboard.customer_data} />
                            <Calendar />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} md={12}>
                            <TrafficSource data={getTopProducts("sold")} />
                        </Col>
                        <Col xl={6} md={12}>
                            <VisitorSource data={dashboard.stock_data} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                        <Channels invoices={dashboard.recent_sales} />
                        </Col>
                        <Col xl={6}>
                            <Channels invoices={dashboard.recent_expenses} title="Recent Purchases" />
                        </Col>
                    </Row>
                    </>
                }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;