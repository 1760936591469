import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const terms = [
  {value: 'I', text: 'I'},
  {value: 'II', text: 'II'},
  {value: 'III', text: 'III'}
]

const categories = [
  {value: 'invoice', text: 'Invoice'},
  {value: 'receipt', text: 'Receipt'},
  {value: 'voucher', text: 'Voucher'}
]

const forms = getForms();

export const JournalFields: IField[] = [
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 4}, position: 1 },
  { name: "company_id", type: "select", label: "Student", dataType: "students", dataSrc: Endpoints.students, size: {lg: 4}, position: 2 },
  { name: "bank_id", type: "select", label: "Bank", dataType: "banks", dataSrc: Endpoints.banks, size: {lg: 4}, position: 3 },
  { name: "referenceno", type: "text", label: "Reference #", size: {lg: 4}, position: 4 },
  { name: "term", type: "select", label: "Term", options: terms, position: 5, size: {lg: 4} },
  { name: "year", type: "text", label: "Year", size: {lg: 4}, position: 6 },
  { name: "amount", type: "text", label: "Amount", size: {lg: 4}, position: 7 },
  { name: "rate", type: "text", label: "Rate", size: {lg: 4}, position: 8 },
  { name: "date", type: "text", label: "Date", size: {lg: 4}, position: 9 },
  { name: "draccountid", type: "select", label: "Debit Account", dataType: "accounts", dataSrc: Endpoints.accounts, size: {lg: 4}, position: 10 },
  { name: "craccountid", type: "select", label: "Credit Account", dataType: "accounts", dataSrc: Endpoints.accounts, size: {lg: 4}, position: 11 },
].sort((a: IField, b: IField) => a.position - b.position);