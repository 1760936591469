import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { StudentcategoryFields } from "../../fields/StudentcategoryFields";
import { StudentcategorySchema } from "../../schemas/StudentcategorySchema";
import { Endpoints } from "../../utils/Endpoints";
import { IStudentcategory } from "../../interfaces/IStudentcategory";

const Studentcategories = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [showStudentcategoryDialog, setShowStudentcategoryDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IStudentcategory>({
    name: "",
    remarks: ""
  });

  const columns = [
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "remarks",
      text: "Remarks"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, studentcategory: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(studentcategory, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowStudentcategoryDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        remarks: ""
      });
      setShowStudentcategoryDialog(true);
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: studentcategories.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {
    request.get(Endpoints.schoolStudentCategories, {})
      .then((response: AxiosResponse) => {
        const { studentcategories, totalRecords } = response.data;
        setStudentcategories(studentcategories);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: IStudentcategory) => {
    const items = studentcategories.map((i: IStudentcategory) => {
      if (i.id === item.id){
        return {...i, ...item};
      }

      return i;
    })
    setStudentcategories(items);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Student categories - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Students" breadcrumbItem="Categories" />

          <XModal title={"Add a student category"}
                  width={"lg"}
                  open={showStudentcategoryDialog}
                  toggle={() => setShowStudentcategoryDialog(!showStudentcategoryDialog)}
                  onClose={() => setShowStudentcategoryDialog(false)}>

            <CreateForm
              fields={StudentcategoryFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.schoolStudentCategories + "/" + initialValues.id : Endpoints.schoolStudentCategories}
              submitButtonLabel={initialValues.id ? "Edit Studentcategory" : "Create Studentcategory"}
              validationSchema={StudentcategorySchema}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowStudentcategoryDialog(false)} />

          </XModal>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Student categories
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null,"new")}>
                        Add Student category
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading student categories. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={studentcategories}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Studentcategories;