import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//redux
import { ApiCore } from "../../helpers/api_helper";
import { amountToCurrency, formattedDate } from "../../utils/Helpers";
import { useHistory } from "react-router-dom";

const MemoList = (props: any) => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const {memos, onClose} = props;

  const history = useHistory();

  const rowEvents = {
    onClick: (e: any, memo: any) => {
      history.push("/transactions/memos/" + memo.id);
      onClose();
    },
  };

  const pageOptions = {
    sizePerPage: 25,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow: any = {
    mode: "checkbox",
  };

  const memosListColumns = [
    {
      text: "ID",
      dataField: "id",
      sort: true,
    },
    {
      text: "Date",
      dataField: "created",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, record: any) => {
        return formattedDate(record.created);
      },
    },
    {
      text: "Customer",
      dataField: "company.name",
      sort: true,
    },
    {
      text: "Invoice ID",
      dataField: "invoice_id",
      sort: false,
    },
    {
      text: "Amount",
      dataField: "tot",
      headerAlign: "right",
      align: "right",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, memo: any) => {
        return amountToCurrency(memo.total);
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={memos}
                        columns={memosListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MemoList;
