import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";
import QRCode from "react-qr-code";
import XModal from "../../components/common/XModal";
import MemoList from "./memos";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateForm from "../../components/forms/CreateForm";
import { EmailFields } from "../../fields/EmailFields";
import { EmailSchema } from "../../schemas/EmailSchema";

const InvoiceDetail = () => {
  const [invoice, setInvoice] = useState<any>({});
  const [email, setEmail]= useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMemos, setShowMemos] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.invoices + "/" + id)
      .then((response: AxiosResponse) => {
        setInvoice(response.data);
        const emailMessage: any = {
          sender: tenant.name,
          senderemail: "no-reply@eygo.biz",
          subject: "Invoice #" + response.data.id,
          destination: response.data.company.contactemail,
          message: `Dear ${response.data.company.name},\r\nPlease find attached a copy of your invoice..\r\n\r\nIn case you need further assistance or clarification, feel free to contact us`
        }
        setEmail(emailMessage);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const fetchPdf = (id: string) => {
    toast
    .promise(request
        .get(Endpoints.invoices + '/' + id, { params: { pdf: true } }, "arraybuffer"),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], {type: type})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `invoice-${+ new Date()}.pdf`
          link.click()
        });
  }

  const handleSendEmail = (values: any) => {
    toast
    .promise(request
        .create(Endpoints.emailInvoice + '/' + invoice.id, values),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return data ? data.data : 'Email sent successfully';
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }).then((response: AxiosResponse) => {
          setShowEmail(false);
        });
  }

  const handleOption = async (id: string, type: string) => {
    if (type == "edit") {
      history.push("/transactions/sales/new?id=" + id);
    } else if(type == "pdf") {
      fetchPdf(id);
    } else if (type == "receipt") {
      if (invoice.invtype == "sale") {
        history.push(
          "/transactions/receipts/new?invoiceId=" +
            id +
            "&balance=" +
            invoice.balance +
            "&companyId=" +
            invoice.company_id
        );
      } else {
        history.push(
          "/transactions/vouchers/new?invoiceId=" +
            id +
            "&balance=" +
            invoice.balance +
            "&companyId=" +
            invoice.company_id
        );
      }
    } else if (type == "memo") {
      if (invoice.invtype == "sale") {
        history.push(
          "/transactions/memos/new?invoiceId=" +
            id +
            "&category=credit&companyId=" +
            invoice.company_id
        );
      } else {
        history.push(
          "/transactions/memos/new?invoiceId=" +
            id +
            "&category=debit&companyId=" +
            invoice.company_id
        );
      }
    } else if (type == "list memo"){
      setShowMemos(true);
    } else if (type == "email"){
      setShowEmail(true);
    }
  };

  const round = (num: any) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  const computeAmounts = (item: any) => {
    let tax = 0; let netAmount = 0;
    if (item.tax) {
      tax = item.amount - (item.amount/(1 + (item.tax.taxpct / 100)));
      tax = round(tax);
      netAmount = item.amount - tax
    }
    return [tax,netAmount];
  }

  let totalTax = 0;
  let totalNetAmount = 0;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Invoice Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
          <XModal title={"Memos"}
                  width={"lg"}
                  open={showMemos}
                  toggle={() => setShowMemos(!showMemos)}
                  onClose={() => setShowMemos(false)}>

            <MemoList memos={invoice.memos} onClose={() => setShowMemos(false)} />

          </XModal>

          <XModal title={"Send invoice on email"}
                  width={"lg"}
                  open={showEmail}
                  toggle={() => setShowEmail(!showEmail)}
                  onClose={() => setShowEmail(false)}>

            <CreateForm
              fields={EmailFields}
              initialValues={email}
              submitButtonLabel={"Send Email"}
              validationSchema={EmailSchema}
              debug={false}
              handleUpdate={handleSendEmail}
              onClose={() => setShowEmail(false)} />

          </XModal>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Invoice{" "}
                    {invoice.efris_error_message &&
                    invoice.efris_error_message.length > 0
                      ? `[${invoice.efris_error_message}]`
                      : null}
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(invoice.id, "pdf");
                        }}
                      >
                        PDF Version
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(invoice.id, "email");
                        }}
                      >
                        Send Via Email
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(invoice.id, "edit");
                        }}
                      >
                        Edit Invoice
                      </DropdownItem>
                      <DropdownItem
                          to="#"
                          onClick={() => {
                            handleOption(invoice.id, "list memo");
                          }}
                        >
                          View Memo
                      </DropdownItem>
                      {invoice.balance > 0 ? (
                        <>
                          <DropdownItem
                            to="#"
                            onClick={() => {
                              handleOption(invoice.id, "receipt");
                            }}
                          >
                            Record Payment
                          </DropdownItem>
                          <DropdownItem
                            to="#"
                            onClick={() => {
                              handleOption(invoice.id, "memo");
                            }}
                          >
                            Record Memo
                          </DropdownItem>
                        </>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading invoice. Please wait." />}
                  {!loading && (
                    <>
                      <Row>
                        <Col sm={3}>
                          <div className="invoice-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Invoice For: </h5>
                            <h5 className="font-size-15 mb-2">
                              {invoice.company.name} - {invoice.company.tin}
                            </h5>
                            <p className="mb-1">
                              {invoice.company.street} {invoice.company.city}
                            </p>
                            <p className="mb-1">
                              {invoice.company.contactemail}
                            </p>
                            <p>{invoice.company.contractphone}</p>
                          </div>
                        </Col>

                        <Col sm={4}>
                          {invoice.efris_id ? (
                            <Row>
                              <Col sm={4}>
                                <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                                  <QRCode
                                  size={256}
                                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                  value={invoice.efris_code}
                                  viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <h5>URA EFRIS</h5>
                                <div>
                                  <b>FDN:</b> {invoice.efris_invoice_no}
                                </div>
                                <div>
                                  <b>Device No:</b> {invoice.efris_device_no}
                                </div>
                                <div>
                                  <b>Verification Code:</b> {invoice.efris_verification_code}
                                </div>
                                <div>
                                  <b>Date:</b> {invoice.efris_date_time}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>

                        <Col sm={2}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">
                                Invoice No:{" "}
                              </h5>
                              <p>#{invoice.id}</p>
                              <div>
                                {invoice.balance == 0 ? (
                                  <span className="badge bg-success font-size-12 ms-2">
                                    PAID
                                  </span>
                                ) : (
                                  <span className="badge bg-danger font-size-12 ms-2">
                                    NOT PAID
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">
                                Invoice Date:{" "}
                              </h5>
                              <p>{formattedDate(invoice.created)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th>Price ({invoice.currency.code})</th>
                                <th>Quantity</th>
                                <th className="text-end">Tax ({invoice.currency.code})</th>
                                <th className="text-end">Net Amount ({invoice.currency.code})</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Total ({invoice.currency.code})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoice.items.map((item: any, index: number) => {
                                const amounts = computeAmounts(item);
                                totalTax += amounts[0];
                                totalNetAmount += amounts[1];
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                          item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                            ? item.product.name
                                            : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td> {amountToCurrency(item.rate ? item.rate : 0)}</td>
                                    <td>{amountToCurrency(item.quantity ? item.quantity : 0)}</td>
                                    <td className="text-end">{amountToCurrency(amounts[0] ? amounts[0] : 0)}</td>
                                    <td className="text-end">{amountToCurrency(amounts[1] ? amounts[1] : 0)}</td>
                                    <td className="text-end">
                                      {amountToCurrency(item.amount ? item.amount : 0)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="text-end"
                                >
                                  Sub Total
                                </th>
                                <td className="text-end">
                                  {invoice.currency.code}{" "}
                                  {amountToCurrency(totalNetAmount)}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="border-0 text-end"
                                >
                                  Discount :{" "}
                                </th>
                                <td className="border-0 text-end">
                                  {invoice.currency.code}{" "}
                                  {amountToCurrency(
                                    (invoice.discount * invoice.tot) / 100
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="border-0 text-end"
                                >
                                  Tax
                                </th>
                                  <td className="border-0 text-end">{invoice.currency.code}{" "} {amountToCurrency(totalTax)}</td>
                              </tr>

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="border-0 text-end"
                                >
                                  Total
                                </th>
                                <td className="border-0 text-end">
                                  <h4 className="m-0 fw-semibold">
                                    {invoice.currency.code}{" "}
                                    {amountToCurrency(
                                      invoice.tot -
                                        (invoice.discount * invoice.tot) / 100
                                    )}
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceDetail;
