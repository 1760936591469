import React, { useState } from "react";
import { IModalProps } from "../../interfaces/IModalProps";
import { Button, Modal } from "reactstrap";

const XModal = (props: IModalProps) => {

  const { onClose } = props;

  return (
    <Modal
      style={{maxWidth: '700px', width: '95%'}}
      isOpen={props.open}
      size={props.width}
      toggle={() => !props.open}
      backdrop={"static"}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{props.title}</h5>
        <button
          type="button"
          onClick={onClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {props.children}

    </Modal>
  );


};

export default XModal;