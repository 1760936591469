interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    businessCategory?:string;
    subItems ?: any;
    isHeader ?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Analytics",
        isHeader : true
    },
    {
        id: 139,
        label: "Dashboard",
        icon: "chart-line",
        link: "/dashboard"
    },
    {
        id: 140,
        novidade: false,
        label: "Reports",
        icon: "chart-pie",
        link: "/#",
        subItems: [
            {
                id: 9,
                label: "Cashflow statement",
                link: "/reports/cashflow",
                parentId: 9
            },
            {
                id: 10,
                label: "Budget",
                link: "/reports/budget",
                parentId: 9
            },
            {
                id: 11,
                label: "Trial balance",
                link: "/reports/trialbalance",
                parentId: 9
            },
            {
                id: 12,
                label: "Income statement",
                link: "/reports/incomestatement",
                parentId: 9
            },
            {
                id: 11,
                label: "New Voucher",
                link: "/transactions/vouchers/new",
                parentId: 9
            }
        ]
    },{
        id: 3,
        label: "Transactions",
        isHeader : true
    },
    {
        id: 138,
        label: "Students",
        icon: "users",
        businessCategory: "school",
        link: "/#",
        subItems: [
            {
                id: 1,
                label: "Categories",
                link: "/students/categories",
                parentId: 138                
            },{
                id: 2,
                label: "Students",
                link: "/students/list",
                parentId: 138                
            }
        ]
    },
    {
        id: 141,
        novidade: false,
        label: "Money In",
        icon: "arrow-alt-circle-right",
        link: "/#",
        subItems: [
            {
                id: 9,
                label: "Receipts",
                link: "/transactions/receipts",
                parentId: 8
            },
            {
                id: 10,
                label: "Invoices",
                link: "/transactions/money-in/invoices?invtype=sale",
                parentId: 8
            },
            {
                id: 11,
                label: "New Cash Sale",
                link: "/transactions/sales/new?invtype=sale&paid=true",
                parentId: 8
            },
            {
                id: 11,
                label: "New Credit Sale",
                link: "/transactions/sales/new?invtype=sale",
                parentId: 8
            },
            {
                id: 11,
                label: "New Receipt",
                link: "/transactions/receipts/new",
                parentId: 8
            }
        ]
    },
    {
        id: 142,
        novidade: false,
        label: "Money Out",
        icon: "arrow-alt-circle-left",
        link: "/#",
        subItems: [
            {
                id: 9,
                label: "Vouchers",
                link: "/transactions/vouchers",
                parentId: 9
            },
            {
                id: 10,
                label: "Invoices",
                link: "/transactions/money-in/invoices?invtype=purchase",
                parentId: 9
            },
            ,
            {
                id: 11,
                label: "Requisitions",
                link: "/transactions/money-out/requisitions",
                parentId: 9
            },
            {
                id: 12,
                label: "New Cash Expense",
                link: "/transactions/sales/new?invtype=purchase&paid=true",
                parentId: 9
            },
            {
                id: 13,
                label: "New Credit Expense",
                link: "/transactions/sales/new?invtype=purchase",
                parentId: 9
            },
            {
                id: 14,
                label: "New Voucher",
                link: "/transactions/vouchers/new",
                parentId: 9
            },
        ]
    },
    {
        id: 143,
        novidade: false,
        label: "School Fees",
        icon: "arrow-alt-circle-left",
        link: "/#",
        subItems: [
            {
                id: 9,
                label: "Set School Fees",
                link: "/school/fees/payable",
                parentId: 9
            },
            {
                id: 10,
                label: "Record Fees",
                link: "/school/fees/record",
                parentId: 9
            },
            {
                id: 11,
                label: "View Fees",
                link: "/school/fees/index",
                parentId: 9
            },
            {
                id: 12,
                label: "Fees Advance",
                link: "/school/fees/advance",
                parentId: 9
            },
            {
                id: 13,
                label: "Fees Defaulters",
                link: "/school/fees/defaulters",
                parentId: 9
            }
        ]
    },{
        id: 139,
        label: "Sales Orders",
        icon: "th",
        link: "/sales"
    },{
        id: 139,
        label: "Upload transactions",
        icon: "th",
        link: "/uploads"
    },
    {
        id: 1,
        label: "Inventory",
        isHeader : true
    },
    {
        id: 139,
        label: "Products",
        icon: "th",
        link: "/inventory/products"
    },
    {
        id: 1391,
        label: "Efris Products",
        icon: "th",
        link: "/inventory/products/efris"
    },
    {
        id: 134,
        label: "Suppliers",
        icon: "industry",
        link: "/inventory/suppliers"
    },
    {
        id: 2,
        label: "Stock Transactions",
        icon: "dollar-sign",
        novidade: false,
        link: "/#",
        subItems: [
            {
                id: 9,
                label: "Stock Transactions",
                link: "/inventory/stocks",
                parentId: 2
            },
            {
                id: 10,
                label: "Record Stock",
                link: "/inventory/stocks/new",
                parentId: 9
            }
        ]
    },
    {
        id: 2,
        label: "Stock Report",
        icon: "globe",
        link: "/inventory/stock/report"
    },
    {
        id: 2,
        label: "Stock Locations",
        icon: "globe",
        link: "/inventory/stock/locations"
    },
    {
        id: 1,
        label: "Settings",
        isHeader : true
    },
    {
        id: 140,
        label: "Accounts",
        icon: "th",
        link: "/accounts"
    },{
        id: 138,
        label: "Banks",
        icon: "th",
        link: "/inventory/banks"
    },{
        id: 138,
        label: "Currencies",
        icon: "th",
        link: "/currencies"
    },
];

export { menuItems };
