import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";
import QRCode from "react-qr-code";
import XModal from "../../components/common/XModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateForm from "../../components/forms/CreateForm";
import { EmailFields } from "../../fields/EmailFields";
import { EmailSchema } from "../../schemas/EmailSchema";

const RequisitionDetail = () => {
  const [requisition, setRequisition] = useState<any>({});
  const [email, setEmail] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.requistions + "/" + id)
      .then((response: AxiosResponse) => {
        setRequisition(response.data);
        const emailMessage: any = {
          sender: tenant.name,
          senderemail: "no-reply@eygo.biz",
          subject: "Requisition #" + response.data.id,
          destination: response.data.company.contactemail,
          message: `Dear ${response.data.company.name},\r\nPlease find attached a copy of your requisition..\r\n\r\nIn case you need further assistance or clarification, feel free to contact us`
        }
        setEmail(emailMessage);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const fetchPdf = (id: string) => {
    toast
      .promise(request
        .get(Endpoints.requistions + '/' + id, { params: { pdf: true } }, "arraybuffer"), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Requisition-${+ new Date()}.pdf`
        link.click()
      });
  }

  const handleSendEmail = (values: any) => {
    toast
      .promise(request
        .create(Endpoints.emailRequisition + '/' + requisition.id, values), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return data ? data.data : 'Email sent successfully';
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      }).then((response: AxiosResponse) => {
        setShowEmail(false);
      });
  }

  const handleOption = async (id: string, type: string) => {
    if (type == "edit") {
      history.push("/transactions/money-out/requisitions/new?id=" + id);
    } else if (type == "pdf") {
      fetchPdf(id);
    }
  };

  const displayStatus = () => {
    if (requisition.status == "pending") {
      return <span className="badge bg-secondary font-size-12 ms-2">PENDING</span>
    }
    else
      if (requisition.status == "approved") {
        return <span className="badge bg-success font-size-12 ms-2">APPROVED</span>
      }
      else
        if (requisition.status == "rejected") {
          return <span className="badge bg-danger font-size-12 ms-2">REJECTED</span>
        }
  }

  const round = (num: any) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  const computeAmounts = (item: any) => {
    let tax = 0; let netAmount = 0;
    if (item.tax) {
      tax = item.amount - (item.amount / (1 + (item.tax.taxpct / 100)));
      tax = round(tax);
      netAmount = item.amount - tax
    }
    return [tax, netAmount];
  }

  let totalTax = 0;
  let totalNetAmount = 0;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Requisition Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Requisitions" breadcrumbItem="Requisition Detail" />
          <XModal title={"Send requisition on email"}
            width={"lg"}
            open={showEmail}
            toggle={() => setShowEmail(!showEmail)}
            onClose={() => setShowEmail(false)}>

            <CreateForm
              fields={EmailFields}
              initialValues={email}
              submitButtonLabel={"Send Email"}
              validationSchema={EmailSchema}
              debug={false}
              handleUpdate={handleSendEmail}
              onClose={() => setShowEmail(false)} />

          </XModal>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Requisition{" "}
                    {requisition.efris_error_message &&
                      requisition.efris_error_message.length > 0
                      ? `[${requisition.efris_error_message}]`
                      : null}
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(requisition.id, "pdf");
                        }}
                      >
                        PDF Version
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(requisition.id, "edit");
                        }}
                      >
                        Edit Requsition
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading requisition. Please wait." />}
                  {!loading && (
                    <>
                      <Row>
                        <Col sm={3}>
                          <div className="invoice-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Requisition For: </h5>
                            <h5 className="font-size-15 mb-2">
                              {requisition.company.name} - {requisition.company.tin}
                            </h5>
                            <p className="mb-1">
                              {requisition.company.street} {requisition.company.city}
                            </p>
                            <p className="mb-1">
                              {requisition.company.contactemail}
                            </p>
                            <p>{requisition.company.contractphone}</p>
                          </div>
                        </Col>

                        <Col sm={4}>
                          {requisition.efris_id ? (
                            <Row>
                              <Col sm={4}>
                                <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                                  <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={requisition.efris_code}
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <h5>URA EFRIS</h5>
                                <div>
                                  <b>FDN:</b> {requisition.efris_invoice_no}
                                </div>
                                <div>
                                  <b>Device No:</b> {requisition.efris_device_no}
                                </div>
                                <div>
                                  <b>Verification Code:</b> {requisition.efris_verification_code}
                                </div>
                                <div>
                                  <b>Date:</b> {requisition.efris_date_time}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>

                        <Col sm={2}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">
                                Requisition No:{" "}
                              </h5>
                              <p>#{requisition.id}</p>
                              <div>
                                <h5 className="font-size-15 mb-1">
                                  Requisition Status :{" "}
                                </h5>
                                {displayStatus()}
                              </div>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">
                                Requisition Date:{" "}
                              </h5>
                              <p>{formattedDate(requisition.date)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th className="text-center">Price (UGX)</th>
                                <th className="text-center">Quantity</th>
                                <th
                                  className="text-center"
                                  style={{ width: "120px" }}
                                >
                                  Total (UGX)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {requisition.items.map((item: any, index: number) => {
                                const amounts = computeAmounts(item);
                                totalTax += amounts[0];
                                totalNetAmount += amounts[index];
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                            item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                              ? item.product.name
                                              : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td className="text-center"> {amountToCurrency(item.rate)}</td>
                                    <td className="text-center">{amountToCurrency(item.quantity)}</td>
                                    <td className="text-center">
                                      {amountToCurrency(item.amount)}
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <th
                                  scope="row"
                                  colSpan={4}
                                  className="border-0 text-end"
                                >
                                  <h6 className="m-0 fw-semibold">
                                    Total
                                  </h6>
                                </th>
                                <td className="border-0 text-end">
                                  <h6 className="m-0 fw-semibold">
                                    UGX{" "}
                                    {amountToCurrency(
                                      requisition.total
                                    )}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RequisitionDetail;
