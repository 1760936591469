import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import AccountAPI from "../../services/AccountAPI";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProductFields } from "../../fields/ProductFields";
import { ProductGroupSchema, ProductSchema } from "../../schemas/ProductSchema";
import { Endpoints } from "../../utils/Endpoints";
import { IProduct } from "../../interfaces/products/IProduct";
import { Divider } from "@material-ui/core";
import { ProductGroupFields } from "../../fields/ProductGroupFields";
import { AccountFields } from "../../fields/AccountFields";

const Accounts = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<any>([]);
  const [showAccountDialog, setShowAccountDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    code: ""
  });

  const columns = [
    {
      dataField: "name",
      text: "Account Name"
    },
    {
      dataField: "code",
      text: "Code"
    },
    {
      dataField: "special_category",
      text: "Category"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) =>
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowAccountDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        account: ""
      });
      setShowAccountDialog(true);
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: accounts.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.accounts + "?term=", {})
      .then((response: AxiosResponse) => {

        const { accounts, totalRecords } = response.data;
        setAccounts(accounts)
        setLoading(false)

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: any) => {
    const items = accounts.map((i: any) => {
      if (i.id === item.id) {
        return { ...i, ...item };
      }

      return i;
    })
    setAccounts(items);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Accounts - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Inventory" breadcrumbItem="Accounts" />

          <XModal title={"Add an account"}
            width={"lg"}
            open={showAccountDialog}
            toggle={() => setShowAccountDialog(!showAccountDialog)}
            onClose={() => setShowAccountDialog(false)}>

            <CreateForm
              fields={AccountFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.accounts + "/" + initialValues.id : Endpoints.accounts}
              submitButtonLabel={initialValues.id ? "Edit Account" : "Add Account"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowAccountDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Accounts
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                        Add account
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading accounts. Please wait." />}
                  {!loading &&
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Category</th>
                          <th className="text-end"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {accounts.map((item: any) => {
                          return (<><tr key={item.id}>
                            <td><b>{item.code}</b></td>
                            <td><a href={`/accounts/${item.id}?sdate=${initialValues.sdate}&edate=${initialValues.edate}`}><b>{item.name}</b></a></td>
                            <td>{item.category}</td>
                            <td className="text-end">
                              <UncontrolledDropdown>
                                <DropdownToggle className="card-drop" tag="a">
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem to="#" onClick={() => handleOption(item, "edit")}>
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                            {item.children && item.children.map((item: any) => {
                              return <><tr key={item.id}>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.code}</td>
                                <td><a href={`/accounts/${item.id}?sdate=${initialValues.sdate}&edate=${initialValues.edate}`}>{item.name}</a></td>
                                <td>{item.category}</td>
                                <td className="text-end">
                                  <UncontrolledDropdown>
                                    <DropdownToggle className="card-drop" tag="a">
                                      <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem to="#" onClick={() => handleOption(item, "edit")}>
                                        <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                              </>
                            })}</>)
                        })}
                      </tbody>
                    </table>
                  }

                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Accounts;