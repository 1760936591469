import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AxiosError, AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Endpoints } from "../../../utils/Endpoints";
import { amountToCurrency, dateToDbFormat, formattedDate } from "../../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import { invoiceEndDate, invoiceStartDate } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XSearchableSelect } from "../../../components/common/inputs/XSearchableSelect";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import accounts from "../../../components/accounts";

const feesAdvances = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [forms, setForms] = useState<any>([]);
  const [records, setRecords] = useState<any>([]);

  const history = useHistory();
  let initialValues: any = {};

  const loadSchoolAdvances = (values: any) => {
    if (values.pdf && values.pdf == 1) {
      fetchFile(values)
    } else {
      fetchHtml(values)
    }
  };

  const fetchFile = (values: any) => {
    toast
      .promise(request
        .get(Endpoints.feesAdvances, { params: { form: values.form, stream: values.stream, edate: values.edate, pdf: 1 } }, "arraybuffer"), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `FeesAdvance-${+ new Date()}.pdf`
        link.click()
      });
  }

  const fetchHtml = (values: any) => {
    toast
      .promise(request
        .get(Endpoints.feesAdvances, { params: { form: values.form, stream: values.stream, edate: values.edate, } }), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const { records } = response.data;
        setRecords(records);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }


  const request = new ApiCore();

  const handleSubmit = (values: any) => {
    console.log(values);
    loadSchoolAdvances(values);
  };

  useEffect(() => {
    loadSchoolAdvances({});
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.getSchoolFeesDefaults, {})
      .then((response: AxiosResponse) => {
        const { forms } = response.data;
        let options: any = [];
        options = forms.map((data: any) => ({
          value: data,
          label: data,
          data: JSON.stringify(data),
        }));
        setForms(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const computeTotals = (item: any) => {
    let totalAmount = 0;

    for (let i = 0; i < item.length; i++) {
      totalAmount += item[i].amount;
    }
    return amountToCurrency(totalAmount);
  }

  const computeAmounts = (item: any): number => {
    let totalAmount = 0;

    for (let i = 0; i < item.length; i++) {
      totalAmount += item[i].amount;
    }
    return totalAmount;
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Fees Advance | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="View" breadcrumbItem="Fees Advance" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Fees Advance</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {!loading && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={3}>
                                  <XSearchableSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={forms}
                                    label={"Class"}
                                    onChange={(value: any) => {
                                      setFieldValue("form", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    id="stream"
                                    type={"text"}
                                    name={"stream"}
                                    label={"Stream"}
                                    onChange={(value: any) => {
                                      setFieldValue("stream", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XDatePickerField
                                    key="edate"
                                    id="edate"
                                    name={"edate"}
                                    label={"End Date"}
                                    touched={touched}
                                    onChange={setFieldValue}
                                    value={values.edate}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <div>&nbsp;</div>
                                  <XCheckbox
                                    key="pdf"
                                    id=""
                                    name={"pdf"}
                                    label={"PDF"}
                                    value="1"
                                    includeTopPadding={false}
                                    onChange={setFieldValue}
                                    size={{}} />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Search
                                  </Button>
                                </Col>
                              </Row>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th><b>Student ID</b></th>
                                    <th><b>Name</b></th>
                                    <th><b>Class</b></th>
                                    <th className="text-center"><b>Balance</b></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {records && records.map((item: any) => {
                                    return (<><tr key={item.id}>
                                      <td>{item.student ? item.student.studentid : ""}</td>
                                      <td>{item.student ? item.student.lastname + " " + item.student.firstname : ""}</td>
                                      <td>{item.form}</td>
                                      <td className="text-center">{amountToCurrency(item.amount)}</td>
                                    </tr>
                                    </>)
                                  })}
                                  <tr>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{"TOTAL"}</b></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center"><b>{amountToCurrency(computeAmounts(records))}</b></td>
                                  </tr>
                                </tbody>
                              </table>
                            </Form>
                            <hr />
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default feesAdvances;
