import * as Yup from "yup";

export const ProductSchema = Yup.object().shape({
  name: Yup.string().min(3, "The name is too short").max(180, "The name is too long").required("Please enter the product name"),
  producttype: Yup.string().required("Please select a product type"),
  minstock: Yup.number().required("Please enter the minimum stock"),
  maxstock: Yup.number().required("Please enter the maximum stock"),
  opstock: Yup.number().required("Please enter the opening stock"),
  code: Yup.string().required()
});

export const ProductGroupSchema = Yup.object().shape({
  name: Yup.string().min(3, "The name is too short").required("Please enter the group name"),
  code: Yup.string().required("Please enter the group code"),
})