import React, { useEffect, useState } from "react";
import { Container, Row, Col, Label, Button, Input, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

import { Formik, Field, Form } from "formik";

//import images
import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo-colored.svg";
import { IUser } from "../../interfaces/IUser";
import { SignupSchema } from "../../schemas/SignupSchema";
import { ITenant } from "../../interfaces/ITenant";
import { CreateBusinessSchema } from "../../schemas/CreateBusinessSchema";

// import data
import { Countries } from "../../data/countries";
import {BusinessCategories} from "../../data/businessCategories";
import axios from "axios";

const CreateBusiness = () => {

  const [tenant, setTenant] = useState({
    name: "",
    telephone: "",
    email: "",
    category: "Retail",
    tin: "",
    logo: "",
    userId: "",
    address: "",
    country: "Uganda"
  });

  const handleSubmit = (values: any) => {
    console.log(values)
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add your business to Treda</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
                <div className="py-5">
                  <div className="text-center mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5 className="">Add your business</h5>
                    <p>Grow your business by managing it with Treda.</p>
                  </div>

                  <Formik validationSchema={CreateBusinessSchema}
                          initialValues={tenant}
                          onSubmit={(values) => handleSubmit(values)}>

                    {({isSubmitting, errors, touched}) => (
                      <Form>
                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="name" type="text"
                                 className={`form-control ${touched.name && errors.name && "is-invalid"}`}
                                 id="input-name"
                                 placeholder="Enter your name" />
                          <Label htmlFor="input-name">Business name</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-building ${touched.name && errors.name && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="category" as="select"
                                 className={`form-select ${touched.category && errors.category && "is-invalid"}`}
                                 id="input-name"
                                 placeholder="Enter your name">
                            {BusinessCategories.map(category => (<option key={category.value}>{category.text}</option>))}
                          </Field>
                          <Label htmlFor="input-name">Category</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-list-alt ${touched.category && errors.category && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="country" as="select"
                                 className={`form-select ${touched.country && errors.country && "is-invalid"}`}
                                 id="input-name"
                                 placeholder="Enter your name">
                            {Countries.map(country => (<option key={country.value}>{country.text}</option>))}
                          </Field>
                          <Label htmlFor="input-name">Country</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-map-pin ${touched.country && errors.country && "text-danger"}`}></i>
                          </div>
                        </div>


                        <div className="mt-3">
                          <Button color="primary"
                                  disabled={isSubmitting}
                                  className="btn btn-primary w-100" type="submit">
                            {isSubmitting ? <Spinner size={"sm"}></Spinner> : "Create business"}
                          </Button>
                        </div>
                      </Form>
                    )}


                  </Formik>

                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy; {new Date().getFullYear()} Treda.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateBusiness;