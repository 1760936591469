import React, { useState } from "react";

//import Breadcrumbs

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { ApiCore } from "../../../helpers/api_helper";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";

const GridForm = (props: any) => {
  const [marktypes, setMarktypes] = useState<any>([]);

  const request = new ApiCore();
  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  const categories: ISelectOption[] = [
    { value: "expenditure", text: "Expenditure" },
    { value: "revenue", text: "Revenue" },
  ];

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {(
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                    <Row>
                        <Col lg={2}>
                          <XTextBox
                            key="year"
                            id="year"
                            type="number"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            value={values.year}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            key="quarter"
                            id="quarter"
                            type="text"
                            name={"quarter"}
                            label={"Period"}
                            touched={touched}
                            value={values.period}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="category"
                            id="category"
                            name={"category"}
                            options={categories}
                            label={"Type"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GridForm;
