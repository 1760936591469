import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const CreateBudget = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [grid, setGrid] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<any>({
    year: "",quarter: "",category: ""
  });

  const request = new ApiCore();

  const loadBudgets = (values: any) => {
    setInitialValues(values);
    toast.promise(request.get(Endpoints.budgetEstimates, {params: {year: values.year, quarter: values.quarter, category: values.category}} ), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
        const data = response.data;
        updateGrid(data, values);
    });
  }

  const handleSubmit = (values: any) => {
    setInitialValues(values);
    toast.promise(request.create(Endpoints.budgets, values), {
      pending: "Please wait..",
      success: {
        render({data}) {
          return "Estimated updated successfully";
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  const updateGrid = (data: any, values: any) => {
    setGrid(data.accounts);
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Budget Estimates - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Create Estimates
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading budget. Please wait." />}
                  {!loading &&
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <GridForm
                            initialValues={initialValues}
                            handleSearch={(values: any) => {
                              loadBudgets(values);
                            }}
                            
                          />
                          <ToastContainer />
                        </Col>
                      </Row>
                      {grid.length > 0 &&
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                            <>
                              <Form>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Code</th>
                                      <th>Name</th>
                                      <th>Category</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {grid.map((item: any) => {
                                    return (<><tr key={item.id}>
                                      <td><b>{item.code}</b></td>
                                      <td><b>{item.name}</b></td>
                                      <td>{item.category}</td>
                                      <td>
                                      <XTextBox
                                        type="text"
                                        key={`estimate.accountid_${item.id.toString()}`}
                                        id={`estimate.accountid_${item.id.toString()}`}
                                        name={`estimate[accountid_${item.id.toString()}]`}
                                        touched={touched}
                                      />
                                      </td>
                                    </tr>
                                    {item.children.map((item: any) => {
                                      return <><tr key={item.id}>
                                      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.code}</td>
                                      <td>{item.name}</td>
                                      <td>{item.category}</td>
                                      <td>
                                      <XTextBox
                                        type="text"
                                        key={`estimate.accountid_${item.id.toString()}`}
                                        id={`estimate.accountid_${item.id.toString()}`}
                                        name={`estimate[accountid_${item.id.toString()}]`}
                                        touched={touched}
                                      />
                                      </td>
                                    </tr>
                                      </>
                                    })}</>)
                                  })}
                                  </tbody>
                                </table>
                                <div>                          
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    name="btn-submit"
                                    className="btn btn-primary"
                                  >
                                    SAVE
                                  </Button>
                                </div>
                              </Form>
                            <hr/>
                          </>
                        )}
                      </Formik>
                      }
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default CreateBudget;