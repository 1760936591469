import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { SupplierFields } from "../../fields/SupplierFields";
import { SupplierSchema } from "../../schemas/SupplierSchema";
import { Endpoints } from "../../utils/Endpoints";
import { ISupplier } from "../../interfaces/suppliers/ISupplier";
import { LocationFields } from "../../fields/LocationFields";

const Locations = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [locations, setLocations] = useState<any>([]);
  const [showSupplierDialog, setShowSupplierDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    code: ""
  });

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true
    },
    {
      dataField: "code",
      text: "Code",
      sort: false
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, item: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(item, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const handleOption = (item: any, type: string) => {
    
    if (type == "edit") {
      setInitialValues(item);
      setShowSupplierDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        code: ""
      });
      setShowSupplierDialog(true);
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: locations.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.locations, {})
      .then((response: AxiosResponse) => {
        const { locations, totalRecords } = response.data;
        setLocations(locations);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: ISupplier) => {
    const items = locations.map((i: ISupplier) => {
      if (i.id === item.id){
        return {...i, ...item}
      }

      return i
    })
    setLocations(items);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stock Locations - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Inventory" breadcrumbItem="Stock Locations" />

          <XModal title={initialValues.id ? "Edit location" : "Add a location"}
                  width={"lg"}
                  open={showSupplierDialog}
                  toggle={() => setShowSupplierDialog(!showSupplierDialog)}
                  onClose={() => setShowSupplierDialog(false)}>

            <CreateForm
              fields={LocationFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.locations + "/" + initialValues.id : Endpoints.locations}
              submitButtonLabel={"Save"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowSupplierDialog(false)} />

          </XModal>

          <Row>
            <Col className="col-xxl-12" lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Stock Location
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null,"new")}>
                        Add a New
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading locations. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={locations}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col lg="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-nowrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Locations;