import { Col, Row, Spinner } from "reactstrap";
import React from "react";

export const Loading = ({label = "Please wait..."} :  any) => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center mt-3 mb-3">
        <Spinner className="mx-auto" color={"primary"}></Spinner>
        <p className={"text-muted"}>{label}</p>
      </Col>
    </Row>
  )
}