export const Urls = ({
  auth: {
    login: '/login',
    signup: '/register',
    logout: '/logout',
    createBusiness: '/register/business',
    addProducts: '/register/business/products',
    resetPassword: '/auth/password/reset'
  },

  home: '/dashboard'
})