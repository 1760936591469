import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";

export const getInvoices = createAsyncThunk("chat/getInvoices", async () => {
  try {
    const request = new ApiCore();

    const response = await request.get(Endpoints.invoices + '?sdate=2020-01-01');
    console.log(response);
    return response.data;
  } catch (error) {
    return error;
  }
});
