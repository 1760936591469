import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";

const Details = () => {
  const [voucher, setVoucher] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.vouchers + "/" + id)
      .then((response: AxiosResponse) => {
        setVoucher(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleEdit = async (id: string) => {
    history.push("/transactions/vouchers/new?id="+id);
  };

  const handleOption =(id: string, action: string) => {
    if (action == "edit") {
      handleEdit(id);
    } else {
      fetchPdf(id);
    }
  };

  const fetchPdf = (id: string) => {
    toast
    .promise(request
        .get(Endpoints.vouchers + '/' + id, { params: { pdf: true } }, "arraybuffer"),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], {type: type})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `voucher-${+ new Date()}.pdf`
          link.click()
        });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Voucher Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Vouchers" breadcrumbItem="Voucher Detail" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Voucher</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(voucher.id, "pdf");
                        }}
                      >
                        PDF Version
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(voucher.id,"edit");
                        }}
                      >
                        Edit Voucher
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading voucher. Please wait." />}
                  {!loading && (
                    <>
                      <Row>
                        <Col sm={4}>
                          <div className="voucher-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Voucher For: </h5>
                            <h5 className="font-size-15 mb-2">
                              {voucher.company.name}
                            </h5>
                            <p className="mb-1">
                              {voucher.company.street} {voucher.company.city}
                            </p>
                            <p className="mb-1">
                              {voucher.company.contactemail}
                            </p>
                            <p>{voucher.company.contractphone}</p>
                          </div>
                        </Col>

                        <Col sm={4}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">
                                Voucher No:{" "}
                              </h5>
                              <p>#{voucher.id}</p>
                              <div>
                                {voucher.balance == 0 ? (
                                  <span className="badge bg-success font-size-12 ms-2">
                                    PAID
                                  </span>
                                ) : (
                                  <span className="badge bg-danger font-size-12 ms-2">
                                    NOT PAID
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">
                                Voucher Date:{" "}
                              </h5>
                              <p>{formattedDate(voucher.created)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Particulars</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Total ({voucher.currency.code})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {voucher.items.map((item: any, index: number) => {
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                          item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                            ? item.product.name
                                            : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {amountToCurrency(item.amount)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={2}
                                  className="text-end"
                                >
                                  Total
                                </th>
                                <td className="text-end">
                                  {voucher.currency.code}{" "}
                                  {amountToCurrency(voucher.total)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Details;
