import { Field } from "formik";
import { Col, Label } from "reactstrap";
import { useEffect, useState } from "react";
import { ISelectProps } from "../../../interfaces/inputs/ISelectProps";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";
import Select from "react-select";
import { ISearchableSelectProps } from "../../../interfaces/inputs/ISearchableSelectProps";

export const XSearchableSelect = (props: ISearchableSelectProps) => {
  const isTouched = props.touched && props.touched[props.name];
  const hasErrors = props.errors && props.errors[props.name];

  const {
    dataType,
    dataSrc,
    handleNewClick,
    allowAddNew,
    handleAddNew,
    onChange,
    ...rest
  } = props;
  const myoptions = props.options;
  const [loading, setLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<ISelectOption[]>(props.options || []);
  const request = new ApiCore();

  useEffect(() => {
    if (myoptions && !dataSrc) {
      if (allowAddNew) {
        myoptions.unshift({
          value: '-1',
          label: "[Add New]",
          text: ''
        });
      }
      setLoading(false);
      setOptions(myoptions);
    } else if (props.dataSrc) {
      request.get(props.dataSrc).then((response: AxiosResponse) => {
        const { data } = response;

        let options: any = [];
        if (allowAddNew) {
          options.push({
            value: -1,
            label: "[Add New]",
          });
        }
        if (Array.isArray(data)) {
          options = data.map((data: any) => ({
            value: data.id,
            label: data.name || data.taxid,
            data: JSON.stringify(data),
          }));
        } else {
          if (dataType) {
            options = data[dataType].map((data: any) => ({
              value: data.id,
              label: data.name || data.taxid,
              data: JSON.stringify(data),
            }));
          }
        }

        setOptions(options);
        setLoading(false);
      });
    }
  }, [props.dataSrc, props.options]);

  const handleChange = (value: any) => {
    onChange?.(value);
  };

  const getValueOption = (value: any) => {
    const matches = options.filter(v => v.value == value);
    return matches[0];
  }

  return (
    <Col {...props.size}>
      <div
        className={`mb-3 ${
          props.icon ? "form-floating form-floating-custom" : ""
        }`}
      >
        {!props.icon && props.label && (
          <Label className={hasErrors && "text-danger"} htmlFor={props.id}>
            {props.label}
            {handleNewClick ? (
              <a href="#" onClick={handleNewClick}>
                &nbsp;&nbsp;[Add New]
              </a>
            ) : null}
          </Label>
        )}

        <Select
          id={props.id}
          options={options}
          multi={false}
          onChange={handleChange}
          value={getValueOption(props.value)}
        />

        {props.icon && (
          <>
            <Label className={hasErrors && "text-danger"} htmlFor={props.id}>
              {props.label}
            </Label>
            <div className="form-floating-icon">
              <i
                className={`fa ${props.icon} ${hasErrors && "text-danger"}`}
              ></i>
            </div>
          </>
        )}
      </div>
    </Col>
  );
};
