import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";

const StockrecordDetail = () => {
  const [stockrecord, setStockrecord] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    request
      .get(Endpoints.stockrecords + "/" + id)
      .then((response: AxiosResponse) => {
        setStockrecord(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  //Print the Stockrecord
  const printStockrecord = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stockrecord Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Stockrecords" breadcrumbItem="Stockrecord Detail" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Stockrecord</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading stockrecord. Please wait." />}
                  {!loading && (
                    <>
                      <Row>
                        <Col sm={4}>
                          <div className="stockrecord-title">
                            <div className="mb-4">
                              <img
                                src={fullUrl(tenant.logo)}
                                alt="logo"
                                height="28"
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-1">{tenant.name}</p>
                              <p className="mb-1">
                                <i className="uil uil-envelope-alt me-1"></i>{" "}
                                {tenant.email}
                              </p>
                              <p>
                                <i className="uil uil-phone me-1"></i>{" "}
                                {tenant.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="text-muted">
                            <h5 className="font-size-16 mb-3">Entity: </h5>
                            <h5 className="font-size-15 mb-2">
                              {stockrecord.company ? stockrecord.company.name : ""}
                            </h5>
                            <p className="mb-1">
                              {stockrecord.company ? stockrecord.company.street: ""} {stockrecord.company ? stockrecord.company.city : ""}
                            </p>
                            <p className="mb-1">
                              {stockrecord.company ? stockrecord.company.contactemail : "'"}
                            </p>
                            <p>{stockrecord.company ? stockrecord.company.contractphone : ""}</p>
                          </div>
                        </Col>

                        <Col sm={4}>
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-15 mb-1">
                                Record No:{" "}
                              </h5>
                              <p>#{stockrecord.id}</p>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-15 mb-1">
                                Date:{" "}
                              </h5>
                              <p>{formattedDate(stockrecord.date)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Rate
                                </th>
                                <th>Batch No.</th>
                                <th>Expiry</th>
                              </tr>
                            </thead>
                            <tbody>
                              {stockrecord.items.map((item: any, index: number) => {
                                return (
                                  <tr key={item.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {item.particulars &&
                                          item.particulars.length > 0
                                            ? item.particulars
                                            : item.product
                                            ? item.product.name
                                            : "N/A"}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>{amountToCurrency(item.quantity)}</td>
                                    <td className="text-end">
                                      {amountToCurrency(item.amount)}
                                    </td>
                                    <td>{item.batchno}</td>
                                    <td>{item.expiry}</td>
                                  </tr>
                                );
                              })}

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StockrecordDetail;
