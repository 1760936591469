import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import ProductAPI from "../../services/ProductAPI";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProductFields } from "../../fields/ProductFields";
import { ProductGroupSchema, ProductSchema } from "../../schemas/ProductSchema";
import { Endpoints } from "../../utils/Endpoints";
import { IProduct } from "../../interfaces/products/IProduct";
import { Divider } from "@material-ui/core";
import { ProductGroupFields } from "../../fields/ProductGroupFields";
import UpdateForm from "../../components/forms/UpdateForm";
import { Urls } from "../../routes/Urls";

const Products = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingGroups, setLoadingGroups] = useState<boolean>(true)
  const [products, setProducts] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false)
  const [showProductEditDialog, setShowProductEditDialog] = useState<boolean>(false)
  const [showProductGroupDialog, setShowProductGroupDialog] = useState<boolean>(false)
  const [product, setProduct] = useState<any>()

  const history = useHistory()

  const handleProductEdit = async (id: string) => {
    const { data } = await request.get(`${Endpoints.products}/${id}`, {})
    setProduct(data)
    setShowProductEditDialog(true)
  }

  const handleViewProduct = (id: any) => {
    history.push(`/inventory/products/${id}`)
  }

  const columns = [
    {
      dataField: "name",
      text: "Product Name"
    },
    {
      dataField: "producttype",
      text: "Type"
    },
    {
      dataField: "sellingprice",
      text: "Selling Price"
    },
    {
      dataField: "costprice",
      text: "Cost Price"
    },
    {
      dataField: "opstock",
      text: "Opening Stock"
    },
    {
      dataField: "stockbal",
      text: "In Stock"
    },
    {
      dataField: "efris",
      text: "Efris"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleViewProduct(product.id)}>
            <i className="mdi mdi-eye font-size-16 text-success me-1"></i>Details
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleProductEdit(product.id)}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
          <DropdownItem>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 100,
    totalSize: products.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.productGroups)
      .then((response: AxiosResponse) => {

        // const { products, totalRecords } = response.data;
        // setGroups(products);
        // setLoadingGroups(false);

      })
      .catch((error) => {
        // console.error(error);
      });

    request.get(Endpoints.products, {})
      .then((response: AxiosResponse) => {
        const { products, totalRecords } = response.data;

        setProducts(products);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const initialValues: IProduct = {
    name: "",
    producttype: ""
  };

  const initialGroupValues: any = {
    name: "",
    code: ""
  };

  const handleUpdate = (product: IProduct) => {

    const updatedProducts = products.map((p: IProduct) => {
      if (p.id === product.id){
        return {...p, ...product}
      }

      return p
    })

    setProducts(updatedProducts)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Inventory" breadcrumbItem="Products" />

          <XModal width={"md"} open={showProductGroupDialog}
                  title={"Add product group"}
                  toggle={() => setShowProductGroupDialog(!showProductGroupDialog)}
                  onClose={() => setShowProductGroupDialog(false)}>

            <CreateForm submitButtonLabel={"Add Group"}
                        initialValues={initialGroupValues}
                        debug={false}
                        submitEndpoint={Endpoints.productGroups}
                        validationSchema={ProductGroupSchema}
                        onClose={() => setShowProductGroupDialog(false)}
                        fields={ProductGroupFields} />

          </XModal>

          <XModal title={"Add a product"}
                  width={"lg"}
                  open={showProductDialog}
                  toggle={() => setShowProductDialog(!showProductDialog)}
                  onClose={() => setShowProductDialog(false)}>

            <CreateForm
              fields={ProductFields}
              initialValues={initialValues}
              submitEndpoint={Endpoints.products}
              submitButtonLabel={"Create Product"}
              validationSchema={ProductSchema}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowProductDialog(false)} />

          </XModal>

          <XModal title={"Edit a product"}
                  width={"lg"}
                  open={showProductEditDialog}
                  toggle={() => setShowProductEditDialog(!showProductEditDialog)}
                  onClose={() => setShowProductEditDialog(false)}>

            <UpdateForm
              fields={ProductFields}
              initialValues={product}
              submitEndpoint={Endpoints.products}
              submitButtonLabel={"Save Changes"}
              validationSchema={ProductSchema}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowProductEditDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Products
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => setShowProductDialog(true)}>
                        Add product
                      </DropdownItem>
                      <DropdownItem to="#" onClick={() => setShowProductGroupDialog(true)}>
                        Add group
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading products. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={products}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col lg="12">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar style={{width: '100%'}} {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Products;