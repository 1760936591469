import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { AxiosResponse } from "axios";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchStockrecord from "./search";
import { invoiceEndDate as stockrecordEndDate, invoiceStartDate as stockrecordStartDate } from "../../utils/Helpers";
import search from "../../components/search";

const StockrecordList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [stockrecords, setStockrecords] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";

  const history = useHistory();

  const rowEvents = {
    onClick: (e: any, stockrecord: any) => {
      history.push("/inventory/stocks/" + stockrecord.stockrecord_id);
    },
  };

  const searchStockrecord = (values: any) => {
      request
        .get(Endpoints.stockrecords, { params: { sdate: values.sdate, edate: values.edate, invtype: values.invtype, company_id: values.company_id} })
        .then((response: AxiosResponse) => {
          const { stockrecords, totalRecords } = response.data;
          setStockrecords(stockrecords);
          setTotalRecords(totalRecords);
          //setLoading(false);
        })
        .catch(error => {
          console.error(error);
        });
  };

  const request = new ApiCore();

  const pageOptions = {
    sizePerPage: 25,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow: any = {
    mode: "checkbox",
  };

  const stockrecordsListColumns = [
    {
      text: "ID",
      dataField: "id",
      sort: true,
    },
    {
      text: "Date",
      dataField: "date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, record: any) => {
        return formattedDate(record.date);
      },
    },
    {
      text: "Category",
      dataField: "category",
      sort: true
    },
    {
      text: "Particulars",
      dataField: "particulars",
      sort: true,
    },
    {
      text: "Amount",
      dataField: "amount",
      headerAlign: "right",
      align: "right",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, stockrecord: any) => {
        return amountToCurrency(stockrecord.amount);
      },
    },
    {
      text: "Source",
      dataField: "src.name",
      sort: true,
    },
    {
      text: "Destination",
      dataField: "dest.name",
      sort: true,
    },
  ];

  useEffect(() => {
    searchStockrecord({
      sdate: stockrecordStartDate(),
      edate: stockrecordEndDate(),
      company_id: "",
      invtype: invtype,
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stock Transactions | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Inventory" breadcrumbItem="Stock Transactions" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Stock Records</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/inventory/stocks/new");
                        }}
                      >
                        Record Stock
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={stockrecords}
                        columns={stockrecordsListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <SearchStockrecord
                                  sdate={stockrecordStartDate()}
                                  edate={stockrecordEndDate()}
                                  handleSearch={(values: any) => {
                                    searchStockrecord(values);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StockrecordList;
