import React from "react";
import { Card, CardHeader } from "reactstrap";
import SimpleBar from "simplebar-react";

const TrafficSource = (props: any) => {
    const {data, title = "Top sold items"} = props;
    return (
        <React.Fragment>
            <Card className="card-h-100">
                <CardHeader className="border-bottom-0">
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <h5 className="card-title">{title}</h5>
                        </div>

                        <div className="flex-shrink-0">
                            
                        </div>
                    </div>
                </CardHeader>

                <SimpleBar style={{ height: "230px" }}>
                    <ul className="list-unstyled mb-0">
                        {(data || []).map((record: any, key: number) => (
                            <li className="px-4 py-3" key={key}>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <div className="avatar-title bg-light text-primary rounded-circle">
                                                #{key+1}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-muted mb-1 text-truncate">{record.product ? record.product.name : "N/A"}</p>
                                        <h5 className="font-size-16 mb-0 text-truncate">{record.amount}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-start">
                                        <div className="badge badge-soft-success ms-2">{record.percentage} % <i className="uil uil-arrow-up-right text-success ms-1"></i></div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </SimpleBar>
            </Card>
        </React.Fragment>
    );
};

export default TrafficSource;