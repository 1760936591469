import { IField } from "../interfaces/inputs/IField";

const productTypes = [
  {value: 'service', text: 'Service'},
  {value: 'consumable', text: 'Consumable'},
  {value: 'stockable', text: 'Stockable'},
]

export const ProductFields: IField[] = [
  { name: "name", type: "text", label: "Product Name", size: {lg: 6}, position: 1 },
  { name: "code", type: "text", label: "Product Code", size: {lg: 3}, position: 2 },
  { name: "barcode", type: "text", label: "Barcode", size: {lg: 3}, position: 3 },
  { name: "producttype", type: "select", label: "Product Type", options: productTypes, position: 4, size: {lg: 6} },
  { name: "minstock", type: "number", label: "Minimum Stock", size: {lg: 3}, position: 6 },
  { name: "maxstock", type: "number", label: "Maximum Stock", size: {lg: 3}, position: 7 },
  { name: "opstock", type: "number", label: "Opening Stock", size: {lg: 3}, position: 5 },
  { name: "costprice", type: "number", label: "Cost Price", size: {lg: 3}, position: 8 },
  { name: "sellingprice", type: "number", label: "Selling Price", size: {lg: 3}, position: 9 },
  { name: "salesaccount", type: "select", dataSrc: "/api/accounts", dataType: 'accounts', label: "Sales Account", size: {lg: 6}, position: 10 },
  { name: "purchaseaccount", type: "select", dataSrc: "/api/accounts", dataType: 'accounts', label: "Purchase Account", size: {lg: 6}, position: 11 },
  { name: "salesdescription", type: "textarea", label: "Sales Description", size: {lg: 12}, position: 12 },
  { name: "purchasedescription", type: "textarea", label: "Purchase Description", size: {lg: 12}, position: 13 },
  { name: "purchase", type: "checkbox", position: 14, label: "Purchase", size: {lg: 3} },
  { name: "sale", type: "checkbox" , position: 15, label: "Sale", size: {lg: 3} },
].sort((a: IField, b: IField) => a.position - b.position);