import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  DropdownToggle,
  DropdownMenu, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import BulkUpload from "./upload";
import {toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Uploads = () => { 

  const [records, setRecords] = useState<any>([]);
  const request = new ApiCore();

  const handleBulkUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(Endpoints.uploaddata, values, responseType), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      setRecords(response.data);
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Upload transactions - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Uploads" breadcrumbItem="" />

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Upload transactions
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <BulkUpload handleSubmit={handleBulkUpload} initialValues={{autoid: 0}} />
                  {records && records.legnth && (
                    <span>{records.legnth} uploaded</span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Uploads;