import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { IProduct } from "../../interfaces/products/IProduct";

//Import React Router
import {useParams} from "react-router-dom"
import { Loading } from "../../components/common/Loading";

const request = new ApiCore()

const ProductDetails = () => {

  const {id} = useParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [showProductEditDialog, setShowProductEditDialog] = useState<boolean>(false)
  const [product, setProduct] = useState<any | undefined>(undefined)
  const [keys, setKeys] = useState<string[]>([])

  const handleProductEdit = async (id: string) => {
    const { data } = await request.get(`${Endpoints.products}/${id}`, {})
    setProduct(data)
    setShowProductEditDialog(true)
  }

  useEffect(() => {

    request.get(`${Endpoints.products}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response
        setProduct(data)
        setKeys(Object.keys(data))

        setLoading(false)

      })
      .catch((error) => {
        console.error(error)
      });
  }, []);

  const handleUpdate = (product: IProduct) => {
    setProduct(product)
  }

  return (
    <React.Fragment>
      {/*{loading && <Loading label="Loading products. Please wait." />}*/}
      {product && <>
        <div className="page-content">
          <MetaTags>
            <title>Products / {product.name}</title>
          </MetaTags>

          <Container fluid>
            <Breadcrumbs title="Products" breadcrumbItem={product.name ?? ""} />
            <Row>
              <Col md={12} lg={12} xl={12}>
                <Card>
                  <CardHeader className="justify-content-between d-flex align-items-center">
                    <CardTitle className="h4">
                      {product.name}
                    </CardTitle>
                    <Button>Edit</Button>
                  </CardHeader>
                  <CardBody>
                    <ul className="list-unstyled mb-0">
                      {keys.map((key: string, index: number) => {
                        return <li key={index} className="pb-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <p className="text-muted mb-1 font-size-13">{key}</p>
                              <h5 className="mb-0 font-size-14">{product[key]}</h5>
                            </div>
                          </div>
                        </li>
                      })}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>

        </div>
      </>}
    </React.Fragment>
  );
};

export default ProductDetails;