import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { AxiosResponse } from "axios";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchMemo from "./search";
import { invoiceEndDate as memoEndDate, invoiceStartDate as memoStartDate } from "../../utils/Helpers";
import search from "../../components/search";

const List = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [memos, setMemos] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";

  const history = useHistory();

  const handleEdit = async (id: string) => {
    //history.push("/transactions/sales/new?id="+id);
  };

  const rowEvents = {
    onClick: (e: any, memo: any) => {
      history.push("/transactions/memos/" + memo.id);
    },
  };

  const searchMemo = (values: any) => {
      request
        .get(Endpoints.memos, { params: { sdate: values.sdate, edate: values.edate, company_id: values.company_id} })
        .then((response: AxiosResponse) => {
          const { memos, totalRecords } = response.data;
          setMemos(memos);
          setTotalRecords(totalRecords);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
        });
  };

  const request = new ApiCore();

  const pageOptions = {
    sizePerPage: 25,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow: any = {
    mode: "checkbox",
  };

  const memosListColumns = [
    {
      text: "ID",
      dataField: "id",
      sort: true,
    },
    {
      text: "Date",
      dataField: "created",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, record: any) => {
        return formattedDate(record.created);
      },
    },
    {
      text: "Customer",
      dataField: "company.name",
      sort: true,
    },
    {
      text: "Particulars",
      dataField: "remarks",
      sort: false,
    },
    {
      text: "Amount",
      dataField: "tot",
      headerAlign: "right",
      align: "right",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, memo: any) => {
        return amountToCurrency(memo.total);
      },
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, memo: any) => (
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem to="#" onClick={() => handleEdit(memo.id)}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
              Edit
            </DropdownItem>

            <DropdownItem>
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  useEffect(() => {
    searchMemo({
      sdate: memoStartDate(),
      edate: memoEndDate(),
      company_id: "",
      invtype: invtype,
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Memo List | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Memos" breadcrumbItem="Memos List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Memos</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/transactions/memos/new");
                        }}
                      >
                        Add Memo
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={memos}
                        columns={memosListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <SearchMemo
                                  sdate={memoStartDate()}
                                  edate={memoEndDate()}
                                  handleSearch={(values: any) => {
                                    searchMemo(values);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
