import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../../components/common/Breadcrumb";

import { AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Endpoints } from "../../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchReport from "./search";
import { invoiceEndDate, invoiceStartDate } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Budget = () => {
  const params = new URLSearchParams(useLocation().search);
  const [budgetData, setBudgetData] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>({
    sdate: invoiceStartDate(),
    edate: invoiceEndDate(),
    category: "",
    quarter: "",
    year: ""
  });

  const history = useHistory();

  const rowEvents = {
    onClick: (e: any, invoice: any) => {
      history.push("/invoices-detail/" + invoice.id);
    },
  };


  const searchReports = (values: any) => {
    setInitialValues(values);
    toast
    .promise(request.get(Endpoints.budgets, { params: { sdate: values.sdate, edate: values.edate, year: values.year,quarter: values.quarter, category: values.category } }),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          setBudgetData(response.data);
        })
        .catch(error => {
          console.error(error);
        });
  };

  const request = new ApiCore();
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Budget | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Cashflow" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Budget</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/reports/budget/new");
                        }}
                      >
                        Record Estimates
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <SearchReport
                        initialValues={initialValues}
                        handleSearch={(values: any) => {
                          searchReports(values);
                        }}
                      />
                    </Col>
                  </Row>
                  <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Code</th>
                                      <th>Name</th>
                                      <th>Category</th>
                                      <th className="text-end">Estimate</th>
                                      <th className="text-end">Actual</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {budgetData.accounts && budgetData.accounts.map((item: any) => {
                                    return (<><tr key={item.id}>
                                      <td><b>{item.code}</b></td>
                                      <td><a href={`/accounts/${item.id}?sdate=${initialValues.sdate}&edate=${initialValues.edate}`}><b>{item.name}</b></a></td>
                                      <td>{item.category}</td>
                                      <td className="text-end">
                                        {
                                          amountToCurrency(budgetData.budgets[item.id.toString()] ? budgetData.budgets[item.id.toString()].amount : 0)
                                        }
                                      </td>
                                      <td className="text-end">{amountToCurrency(item.amount)}</td>
                                    </tr>
                                    {item.children.map((item: any) => {
                                      return <><tr key={item.id}>
                                      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.code}</td>
                                      <td><a href={`/accounts/${item.id}?sdate=${initialValues.sdate}&edate=${initialValues.edate}`}>{item.name}</a></td>
                                      <td>{item.category}</td>
                                      <td className="text-end">{
                                          amountToCurrency(budgetData.budgets[item.id.toString()] ? budgetData.budgets[item.id.toString()].amount : 0)
                                        }</td>
                                      <td className="text-end">{amountToCurrency(item.amount)}</td>
                                    </tr>
                                      </>
                                    })}</>)
                                  })}
                                  </tbody>
                                </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Budget;
