import * as Yup from "yup";

export const CreateBusinessSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too short").required("Please enter your business name"),
  email: Yup.string().email("Invalid email").required("Please enter your business email address"),
  telephone: Yup.string().required("Please enter your business telephone")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid telephone number"
    ),
});