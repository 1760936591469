import { Redirect } from "react-router-dom";

//Sales
import Sales from "../pages/Sales";
//Dashboard
import Dashboard from "../pages/Dashboard";

//  Inner Authentication
import LoginPage from "../pages/Authentication/LoginPage";
import CreateAccount from "../pages/Authentication/CreateAccount";
import Signout from "../pages/Authentication/Signout";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage";

//Error
import Error1 from "../pages/Error/Error1";
import Error2 from "../pages/Error/Error2";
import Error3 from "../pages/Error/Error3";
import ErrorBasic from "../pages/Error/ErrorBasic";
import ErrorCover from "../pages/Error/ErrorCover";

import Studentcategories from "../pages/Studentcategories";
import Students from "../pages/Students";
import StudentDetails from "../pages/Students/detail";

//utility
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageMaintenance from "../pages/Utility/PageMaintenance";


//pricing

//Invoice
import InvoiceList from "../pages/Invoices/invoice-list";
import InvoiceDetail from "../pages/Invoices/invoice-details";

import SalesOrderList from "../pages/Sales/index";
import SalesOrderDetail from "../pages/Sales/details";
import NewSaleOrder from "../pages/Sales/create";

import CreateBusiness from "../pages/Authentication/CreateBusiness";
import SelectProducts from "../pages/Authentication/SelectProducts";
import Products from "../pages/Products";
import EfrisProducts from "../pages/Products/efris";
import Banks from "../pages/Banks";
import Suppliers from "../pages/Suppliers";
import SupplierDetails from "../pages/Suppliers/details";
import NewSale from "../pages/Invoices/create";
import SalesDashboard from "../pages/Sales/dashboard";
import Accounts from "../pages/Accounts/AccountsList";
import AccountDetails from "../pages/Accounts/details";
import ProductDetails from "../pages/Products/detail";

import ReceiptForm from "../pages/Receipts/create";
import ReceiptList from "../pages/Receipts/list";
import ReceiptDetails from "../pages/Receipts/details";

import VoucherForm from "../pages/Vouchers/create";
import VoucherList from "../pages/Vouchers/list";
import VoucherDetails from "../pages/Vouchers/details";

import MemoForm from "../pages/Memos/create";
import MemoList from "../pages/Memos/list";
import MemoDetails from "../pages/Memos/details";

import StockForm from "../pages/Stock/create";
import StockList from "../pages/Stock/list";
import StockDetails from "../pages/Stock/details";
import StockLocations from "../pages/Locations";
import Tenants from "../pages/Tenants/index";
import Currencies from "../pages/Currencies";
import CashflowStatement from "../pages/Reports/cashflow";
import Budget from "../pages/Reports/budget";
import BudgetEstimate from "../pages/Reports/budget/create";
import TrialBalance from "../pages/Reports/trialbalance";
import IncomeStatement from "../pages/Reports/incomestatement";
import SetSchoolFees from "../pages/SchoolFees/setSchoolFees/create";
import RecordFees from "../pages/SchoolFees/recordFees";
import ViewFees from "../pages/SchoolFees/viewSchoolFees";
import feesAdvances from "../pages/SchoolFees/feesAdvances";
import feesDefaulters from "../pages/SchoolFees/feesDefaulters";
import NewRequisition from "../pages/Requisitions/create";
import RequisitionList from "../pages/Requisitions/requisition-list";
import RequisitionDetail from "../pages/Requisitions/requisition-details";

import Uploads from "../pages/Uploads";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  clazz?: string,
  ability?: string
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  // sales
  { path: "/transactions/sales/new", component: NewSale },
  { path: "/transactions/sales", component: Sales },
  { path: "/transactions/sales/dashboard", component: SalesDashboard },

  // Receipts
  { path: "/transactions/receipts/new", component: ReceiptForm },
  { path: "/transactions/receipts", component: ReceiptList },
  { path: "/transactions/receipts/:id", component: ReceiptDetails },

  // Vouchers
  { path: "/transactions/vouchers/new", component: VoucherForm },
  { path: "/transactions/vouchers", component: VoucherList },
  { path: "/transactions/vouchers/:id", component: VoucherDetails },

  // Memos
  { path: "/transactions/memos/new", component: MemoForm },
  { path: "/transactions/memos", component: MemoList },
  { path: "/transactions/memos/:id", component: MemoDetails },

  { path: "/currencies", component: Currencies, clazz: "Role", ability: "read" },

  { path: "/students/categories", component: Studentcategories },
  { path: "/students/list", component: Students },
  { path: "/students/:id", component: StudentDetails },

  { path: "/uploads", component: Uploads },

  // inventory
  { path: "/inventory/stocks/new", component: StockForm },
  { path: "/inventory/stock/locations", component: StockLocations, clazz: "Location", ability: "manage" },
  { path: "/inventory/stocks", component: StockList },
  { path: "/inventory/stocks/:id", component: StockDetails },
  { path: "/inventory/products", component: Products },
  { path: "/inventory/products/efris", component: EfrisProducts },
  { path: "/inventory/products/:id", component: ProductDetails },
  { path: "/accounts", component: Accounts },
  { path: "/accounts/:id", component: AccountDetails },
  { path: "/inventory/banks", component: Banks, clazz: "Bank", ability: "read" },
  { path: "/inventory/suppliers", component: Suppliers },
  { path: "/inventory/suppliers/:id", component: SupplierDetails },


  //Accounting statements
  { path: "/reports/cashflow", component: CashflowStatement },
  { path: "/reports/budget", component: Budget },
  { path: "/reports/budget/new", component: BudgetEstimate },
  { path: "/reports/trialbalance", component: TrialBalance },
  { path: "/reports/incomestatement", component: IncomeStatement },

  //School fees
  { path: "/school/fees/payable", component: SetSchoolFees },
  { path: "/school/fees/record", component: RecordFees },
  { path: "/school/fees/index", component: ViewFees },
  { path: "/school/fees/advance", component: feesAdvances },
  { path: "/school/fees/defaulters", component: feesDefaulters }, 
  

  //Invoice
  { path: "/invoices-list", component: InvoiceList },
  { path: "/transactions/money-in/invoices", component: InvoiceList },
  { path: "/invoices-detail/:id", component: InvoiceDetail },

    //Sales orders
    { path: "/sales", component: SalesOrderList },
    { path: "/sales/new", component: NewSaleOrder },
    { path: "/sales/:id", component: SalesOrderDetail },

  // Requisition
  { path: "/requisitions-list", component: RequisitionList },
  { path: "/transactions/money-out/requisitions", component: RequisitionList },
  { path: "/requisitions-detail/:id", component: RequisitionDetail },
  { path: "/transactions/money-out/requisitions/new", component: NewRequisition },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: LoginPage },
  { path: "/logout", component: Signout },
  { path: "/register", component: CreateAccount },
  { path: "/register/business", component: CreateBusiness },
  { path: "/register/business/products", component: SelectProducts },
  { path: "/auth/password/reset", component: ResetPasswordPage },

  { path: "/error-404-basic", component: Error1 },
  { path: "/error-404-cover", component: Error2 },
  { path: "/error-403-basic", component: Error3 },
  { path: "/error-500-basic", component: ErrorBasic },
  { path: "/error-500-cover", component: ErrorCover },

  //utility page
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-maintenance", component: PageMaintenance }
];

export { userRoutes, authRoutes };
