import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { JournalFields } from "../../fields/JournalFields";

const request = new ApiCore();

const StudentDetails = () => {
  const { id } = useParams();
  const [student, setStudent] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    loadStudent();
  }, []);

  const loadStudent = () => {
    request
      .get(`${Endpoints.students}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setStudent(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadStudent();
  };

  const handleClick = (record: any, action: string) => {
    if (action == "edit") {
      setInitialValues(record);
      setShowDialog(true);
    } else if (action == "delete") {
      if (window.confirm("Do you want to delete this record?")) {
        request
        .delete(`${Endpoints.generaljournals}/${record.id}`, {})
        .then((response: AxiosResponse) => {
          loadStudent();
        })
        .catch((error) => {
          console.error(error);
        });
      }
    }
  }
  
  return (
    <React.Fragment>
      {student && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Journal"}
                  width={"lg"}
                  open={showDialog}
                  toggle={() => setShowDialog(!showDialog)}
                  onClose={() => setShowDialog(false)}>

            <CreateForm
              fields={JournalFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.generaljournals + "/" + initialValues.id : Endpoints.generaljournals}
              submitButtonLabel={initialValues.id ? "Edit Journal" : "Add Journa;"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowDialog(false)} />

          </XModal>
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <img
                            src={fullUrl(student.photo_path)}
                            alt="logo"
                            height="28"
                          />
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{student.firstname} {student.lastname}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{student.phone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Address</div>
                              <div className="dt-value">{student.address}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Class</div>
                              <div className="dt-value">{student.form} {student.stream}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{student.email}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Payment Code</div>
                              <div className="dt-value">{student.paymentcode}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Gender</div>
                              <div className="dt-value">{student.gender}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Balance</div>
                              <div className="dt-value">{amountToCurrency(student.balance)}</div>
                            </Col>
                          </Row>
                          <hr/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br/>
            <Container fluid>
              <Row>
                <Col md={6} lg={6} xl={6}>
                  <Card>
                    <CardBody>
                      <b>INVOICE(S)</b><br/>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Date</th><th>Particulars</th><th>Amount</th><th></th></tr>
                        </thead>
                        <tbody>
                          {student.dr.map((record: any) => {
                            return <tr key={record.id + '_' + record.company_id}>
                              <td>{formattedDate(record.date)}</td>
                              <td>{record.particulars}</td>
                              <td className="txt-right">{amountToCurrency(record.amount)}</td>
                              <td>
                                <a href="#" onClick={ () => handleClick(record, "edit")}>Edit</a>
                                 &nbsp;&nbsp;|&nbsp; 
                                 <a href="#" onClick={ () => handleClick(record, "delete")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan={2} className="txt-right">TOTAL</th>
                            <th>{amountToCurrency(student.dr.reduce((accumulator: number, object: any) => {
  return accumulator + object.amount;
}, 0))}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <Card>
                    <CardBody>
                    <b>PAYMENT(S)</b><br/>
                    <table className="table bordered">
                        <thead>
                          <tr><th>Date</th><th>Particulars</th><th>Amount</th><th></th></tr>
                        </thead>
                        <tbody>
                          {student.cr.map((record: any) => {
                            return <tr key={record.id + '_' + record.company_id}>
                              <td>{formattedDate(record.date)}</td>
                              <td>{record.particulars}</td>
                              <td className="txt-right">{amountToCurrency(record.amount)}</td>
                              <td>
                                <a href="#" onClick={ () => handleClick(record, "edit")}>Edit</a>
                                 &nbsp;&nbsp;|&nbsp; 
                                 <a href="#" onClick={ () => handleClick(record, "delete")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan={2} className="txt-right">TOTAL</th>
                            <th>{amountToCurrency(student.cr.reduce((accumulator: number, object: any) => {
  return accumulator + object.amount;
}, 0))}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default StudentDetails;
