import { Card, CardBody } from "reactstrap";
import React from "react";

export default function InvoiceSummary({icon, label, value, color }: any) {
  return (
    <Card color={color} outline>
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-3">
            <div className="avatar-sm">
              <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                <i className={`fa ${icon}`}></i>
              </div>
            </div>
          </div>

          <div className="flex-grow-1 overflow-hidden">
            <p className="mb-1 text-truncate text-muted">{label}</p>
            <h5 className="font-size-16 mb-0">{value}</h5>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}