export const BusinessCategories = [
  {
    "value":"retail",
    "text":"Retail"
  },
  {
    "value":"farm",
    "text":"Farm"
  },
  {
    "value":"hotel",
    "text":"Hotel"
  },
  {
    "value":"hospital",
    "text":"Hospital"
  },
  {
    "value":"microfinance",
    "text":"Microfinance"
  },
  {
    "value":"property management",
    "text":"Property Management"
  },
  {
    "value":"products",
    "text":"Products"
  },{
    "value":"restaurant",
    "text":"Restaurant"
  },
  {
    "value":"school",
    "text":"School"
  },
  {
    "value":"services",
    "text":"Services"
  },
  {
    "value":"stock",
    "text":"Stock"
  }

]