import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const genders = [
  {value: 'F', text: 'Female'},
  {value: 'M', text: 'Male'}
]

const statuses = [
  {value: 'active', text: 'Active'},
  {value: 'inactive', text: 'Inactive'}
]

const forms = getForms();

export const StudentFields: IField[] = [
  { name: "firstname", type: "text", label: "First Name", size: {lg: 4}, position: 1 },
  { name: "lastname", type: "text", label: "Last Name", size: {lg: 4}, position: 2 },
  { name: "othername", type: "text", label: "Other Name", size: {lg: 4}, position: 3 },
  { name: "studentid", type: "text", label: "Student ID", size: {lg: 4}, position: 3 },
  { name: "dob", type: "text", label: "Date of Birth", size: {lg: 4}, position: 4 },
  { name: "studentcategory_id", type: "select", label: "Category", dataType: "studentcategories", dataSrc: Endpoints.schoolStudentCategories, size: {lg: 4}, position: 5 },
  { name: "form", type: "select", label: "Class", options: forms, size: {lg: 4}, position: 4 },
  { name: "stream", type: "text", label: "Stream", size: {lg: 4}, position: 7 },
  { name: "gender", type: "select", label: "Gender", options: genders, position: 8, size: {lg: 4} },
  { name: "paymentcode", type: "text", label: "Payment Code", size: {lg: 4}, position: 10 },
  { name: "address", type: "text", label: "Address", size: {lg: 4}, position: 11 },
  { name: "phone", type: "text", label: "Phone", size: {lg: 4}, position: 12 },
  { name: "email", type: "text", label: "Email", size: {lg: 4}, position: 13 },
  { name: "status", type: "select", label: "Status", options: statuses, size: {lg: 4}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);