import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";
import { XSelect } from "../../components/common/inputs/XSelect";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";

const BulkUpload = (props: any) => {

  const categories: ISelectOption[] = [
    { value: "paymentcodes", text: "Payment Codes" },
    { value: "coa", text: "Chart of Accounts" },
    { value: "coacategory", text: "COA Category" },
    { value: "gj", text: "General Journal" },
    { value: "product", text: "Products" },
    { value: "feesbalance", text: "Fees Balance" },
    { value: "fees payments", text: "Fees Payments" },
  ];

  return (
    <React.Fragment>
      <div>
        <Container fluid>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => props.handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={5}>
                          <XFileInput
                            key="file"
                            id="file"
                            name={"file"}
                            label={"Upload Excel File"}
                            onChange={
                              (event: any) => {
                                setFieldValue('file', event.currentTarget.files[0]);
                              }
                            }
                            errors={errors}
                          />
                        </Col>
                        <Col lg={5}>
                          <XSelect
                            key="category"
                            id="category"
                            name={"category"}
                            options={categories}
                            label={"Category"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>                     
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Upload
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkUpload;
