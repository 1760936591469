import React from "react";
import { Route, Redirect } from "react-router-dom";
import { can, isLoggedIn } from "../../utils/Helpers";

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  clazz?: string;
  ability?: string;
  exact?: boolean;
  key?: number;
}

const AuthMiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  clazz = "",
  ability = "",
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component; 

  return (
    <Route
      {...rest}
      render={(props : any) => {
        if (isAuthProtected && !isLoggedIn()) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else if(isAuthProtected && !can(ability,clazz)) {
          return (
            <Redirect
              to={{ pathname: "/error-403-basic", state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AuthMiddleware;
