import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { Endpoints } from "../../../utils/Endpoints";
import { ApiCore } from "../../../helpers/api_helper";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { dateToDbFormat } from "../../../utils/Helpers";

const SearchReport = (props: any) => {
  const params = new URLSearchParams(useLocation().search);


  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  const categories: ISelectOption[] = [
    { value: "expenditure", text: "Expenditure" },
    { value: "revenue", text: "Revenue" },
  ];

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {(
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XTextBox
                            key="year"
                            id="year"
                            type="number"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            value={values.year}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            key="period"
                            id="period"
                            type="text"
                            name={"quarter"}
                            label={"Period"}
                            touched={touched}
                            value={values.period}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="category"
                            id="category"
                            name={"category"}
                            options={categories}
                            label={"Type"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XDatePickerField
                            key="sdate"
                            id="sdate"
                            name={"sdate"}
                            label={"Start Date"}
                            touched={touched}
                            onChange={(f: any, v: Date) => {
                              setFieldValue(f, dateToDbFormat(v));
                            }}
                            value={values.sdate}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XDatePickerField
                            key="sdate"
                            id="edate"
                            name={"edate"}
                            label={"End Date"}
                            touched={touched}
                            onChange={(f: any, v: Date) => {
                              setFieldValue(f, dateToDbFormat(v));
                            }}
                            value={values.edate}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchReport;
