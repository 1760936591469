import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too short").required("Please enter your name"),
  email: Yup.string().email("Invalid email").required("Please enter your email address"),
  telephone: Yup.string().required("Please enter your telephone")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid telephone number"
    ),
  password: Yup.string().min(8, "Too short").required("Please enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
});