import { Field, Formik, useField, useFormikContext } from "formik";
import { Col, Label } from "reactstrap";
import React from "react";
import { ITextBoxProps } from "../../../interfaces/inputs/ITextBoxProps";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const XDatePickerField = (props: any) => {

  const isTouched = props.touched && props.touched[props.name];
  const hasErrors = props.errors && props.errors[props.name];

  return (
    <Col {...props.size}>
      <div className={`mb-3 ${props.icon ? "form-floating form-floating-custom" : ""}`}>

        {!props.icon && props.label &&
          <Label className={hasErrors && "text-danger"}
                 htmlFor={props.id}>{props.label}</Label>
        } 
        <DatePicker
          className={`form-control ${hasErrors && "is-invalid"}`}
          dateFormat="yyyy-MM-dd"
          value={props.value || ''}
          selected={(props.value && new Date(props.value)) || null}
          onChange={(val) => {
            props.onChange(props.name,val);
          }}
        />

        {props.icon &&
          <>
            <Label className={hasErrors && "text-danger"}
                   htmlFor={props.id}>{props.label}</Label>
            <div className="form-floating-icon">
              <i className={`fa ${props.icon} ${hasErrors && "text-danger"}`}></i>
            </div>
          </>
        }
      </div>
    </Col>
  );
};