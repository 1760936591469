import React, { useEffect, useState } from "react";
import { Container, Row, Col, Label, Button, Input, Card, CardBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

import { Formik, Field, Form, useFormik } from "formik";

//import images
import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo-colored.svg";
import { IUser } from "../../interfaces/IUser";
import { SignupSchema } from "../../schemas/SignupSchema";
import { ITenant } from "../../interfaces/ITenant";
import { CreateBusinessSchema } from "../../schemas/CreateBusinessSchema";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { Loading } from "../../components/common/Loading";

// import data

const SelectProducts = () => {

  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      selectedProducts: []
    },
    onSubmit: (values: any) => {

    }
  });

  const handleChange = (e: any) => {
    const { checked, value } = e.target;

    if (checked) {
      formik.setFieldValue("selectedProducts", [...formik.values.selectedProducts, Number(value)]);
    } else {

      const { selectedProducts } = formik.values;
      const valueIndex = selectedProducts.indexOf(Number(value));

      if (valueIndex !== -1) {
        formik.values.selectedProducts.splice(valueIndex, 1);
      }

      formik.setFieldValue(
        "selectedProducts",
        formik.values.selectedProducts
      );
    }
  };

  useEffect(() => {
    fetch("https://dummyjson.com/products")
      .then(res => res.json())
      .then(data => {
        setProducts(data.products);
        setLoading(false);
      });
  }, []);


  return (
    <React.Fragment>
      <MetaTags>
        <title>Select the products you sell</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={4}>
                <div className="py-5">
                  <div className="text-center mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5 className="">Select the products you sell</h5>
                    <p>You can select a few now. You will add more later.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="justify-content-center">

                  {/*{loading && <Loading label={"Loading products."} /> }*/}

                  {!loading &&
                    <form onSubmit={formik.handleSubmit}>

                      <Row className="justify-content-center">

                        {products.map((product: any) => {
                          // @ts-ignore
                          return (
                            <Col key={product.id} xs={12} md={6} lg={3}>
                              <Card>
                                <CardBody>
                                  <div role="group" className="form-check">
                                    <input id={`product-${product.id}`}
                                           name={`product-${product.id}`}
                                           value={product.id}
                                           disabled={formik.isSubmitting}
                                           onChange={handleChange}
                                           checked={formik.values.selectedProducts.includes(product.id)}
                                           className="form-check-input"
                                           type="checkbox" />

                                    <Label htmlFor={`product-${product.id}`} className="form-check-label">
                                      {product.title}
                                    </Label>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}

                      </Row>

                      <Row className="justify-content-center">
                        <Col md={4}>
                          <div className="mt-3">
                            <Button color="primary"
                                    disabled={formik.isSubmitting}
                                    className="btn btn-primary w-100" type="submit">
                              {formik.isSubmitting ? "Adding products to your business..." : "Add products"}
                            </Button>
                          </div>
                        </Col>
                      </Row>

                    </form>
                  }

                </div>
              </Col>
            </Row>


            <Row>
              <Col lg={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy; {new Date().getFullYear()} Treda.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SelectProducts;