import { Form, Formik } from "formik";
import { IProduct } from "../../interfaces/products/IProduct";
import { IFormProps } from "../../interfaces/IFormProps";
import { ProductSchema } from "../../schemas/ProductSchema";
import { Button, Col, Row } from "reactstrap";
import { XTextBox } from "../common/inputs/XTextBox";
import React, { useEffect, useState } from "react";
import { XTextArea } from "../common/inputs/XTextArea";
import { XSelect } from "../common/inputs/XSelect";
import { XCheckbox } from "../common/inputs/XCheckbox";
import resolveBaseUrl from "../../services";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { AxiosError, AxiosResponse } from "axios";

const request = new ApiCore();

const UpdateForm = (props: IFormProps) => {

  const {
    initialValues,
    validationSchema,
    handleUpdate,
    onClose,
    fields,
    submitEndpoint,
    debug
  } = props

  const handleSubmit = (values: any) => {

    if (submitEndpoint){
      request.update(`${submitEndpoint}/${values.id}`, values)
        .then((response: AxiosResponse) => {

          if (handleUpdate)  handleUpdate(response.data);
          if (onClose) onClose()

        }).catch((error: AxiosError) => {

      })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      onSubmit={(values: any) => handleSubmit(values)}>
      {({ isSubmitting, values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="modal-body">
            <Row>
              {fields.map((field: any) => {
                if (field.type === "select") {
                  return <XSelect key={field.name} id={field.name} {...field} touched={touched} errors={errors} />;
                } else if (field.type === "textarea") {
                  return <XTextArea key={field.name} id={field.name} {...field} touched={touched} errors={errors} />;
                } else if (field.type === "checkbox") {
                  return <XCheckbox key={field.name} onChange={setFieldValue} id={field.name} {...field} />;
                } else {
                  return (
                    <XTextBox
                      key={field.name}
                      id={field.name}
                      {...field}
                      errors={errors}
                      touched={touched} />
                  );
                }
              })}

              {debug && <Col xs={12}>
                <pre className={"code"} style={{ width: "100%", height: "100%" }}>
                    {JSON.stringify({ values, errors, touched }, null, 2)}
                </pre>
              </Col>}

            </Row>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              Close
            </button>
            <Button disabled={isSubmitting} type="submit" className="btn btn-primary">
              {props.submitButtonLabel || "Save"}
            </Button>
          </div>

        </Form>
      )}

    </Formik>
  );
};

export default UpdateForm;