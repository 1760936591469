import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../utils/Helpers";
import QRCode from "react-qr-code";
import XModal from "../../components/common/XModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateForm from "../../components/forms/CreateForm";
import { EmailFields } from "../../fields/EmailFields";
import { EmailSchema } from "../../schemas/EmailSchema";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const Details = () => {
  const [company, setCompany] = useState<any>({});
  const [invoices, setInvoices] = useState<any>([]);
  const [email, setEmail]= useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMemos, setShowMemos] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  const invoicesListColumns = [
    {
      text: "ID",
      dataField: "id",
      sort: true,
    },
    {
      text: "Date",
      dataField: "created",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, record: any) => {
        return formattedDate(record.created);
      },
    },
    {
      text: "Amount",
      dataField: "tot",
      headerAlign: "right",
      align: "right",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, invoice: any) => {
        return amountToCurrency(invoice.tot);
      },
    },
    {
      text: "Balance",
      dataField: "balance",
      headerAlign: "right",
      align: "right",
      sort: true,
      formatter: (cellContent: any, invoice: any) => {
        return amountToCurrency(invoice.balance);
      },
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, invoice: any) => (
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem>
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  useEffect(() => {
    request
      .get(Endpoints.companies + "/" + id)
      .then((response: AxiosResponse) => {
        const {company, invoices} = response.data;
        setCompany(company);
        setInvoices(invoices);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const fetchPdf = (id: string) => {
    toast
    .promise(request
        .get(Endpoints.companies + '/' + id, { params: { pdf: true } }, "arraybuffer"),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], {type: type})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `company-${+ new Date()}.pdf`
          link.click()
        });
  }

  const handleSendEmail = (values: any) => {
    toast
    .promise(request
        .create(Endpoints.companies + '/' + company.id, values),{
          pending: "Please wait..",
          success: {
            render({ data }) {
              return data ? data.data : 'Email sent successfully';
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }).then((response: AxiosResponse) => {
          setShowEmail(false);
        });
  }

  const pageOptions = {
    sizePerPage: 25,
    totalSize: invoices.length, // replace later with size(users),
    custom: true,
  };

  const rowEvents = {
    onClick: (e: any, invoice: any) => {
      history.push("/invoices-detail/" + invoice.id);
    },
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const handleOption = async (id: string, type: string) => {
    if (type == "edit") {
      history.push("/transactions/sales/new?id=" + id);
    } else if(type == "pdf") {
      fetchPdf(id);
    } else if (type == "list memo"){
      setShowMemos(true);
    } else if (type == "email"){
      setShowEmail(true);
    }
  };

  const round = (num: any) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  const computeAmounts = (item: any) => {
    let tax = 0; let netAmount = 0;
    if (item.tax) {
      tax = item.amount - (item.amount/(1 + (item.tax.taxpct / 100)));
      tax = round(tax);
      netAmount = item.amount - tax
    }
    return [tax,netAmount];
  }

  let totalTax = 0;
  let totalNetAmount = 0;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Company Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Companys" breadcrumbItem="Company Detail" />

          <XModal title={"Send company on email"}
                  width={"lg"}
                  open={showEmail}
                  toggle={() => setShowEmail(!showEmail)}
                  onClose={() => setShowEmail(false)}>

            <CreateForm
              fields={EmailFields}
              initialValues={email}
              submitButtonLabel={"Send Email"}
              validationSchema={EmailSchema}
              debug={false}
              handleUpdate={handleSendEmail}
              onClose={() => setShowEmail(false)} />

          </XModal>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col><i className="mdi mdi-folder-information-outline font-size-18"></i>&nbsp;&nbsp;{company.name}</Col>
                    <Col><i className="mdi mdi-phone-classic font-size-18"></i>&nbsp;&nbsp;{company.contractphone}</Col>
                    <Col><i className="mdi mdi-cash-multiple font-size-18"></i>&nbsp;&nbsp;{company.balance ? amountToCurrency(company.balance) : 0}</Col>
                  </Row>
                  <hr/>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={invoices}
                        columns={invoicesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Details;
