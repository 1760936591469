import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Endpoints } from "../../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchReport from "./search";
import { invoiceEndDate, invoiceStartDate } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CashflowStatement = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [operating, setOperating] = useState<any>([]);
  const [financing, setFinancing] = useState<any>([]);
  const [investing, setInvesting] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  const handleEdit = async (id: string) => {
    //history.push("/transactions/sales/new?id="+id);
  };

  const rowEvents = {
    onClick: (e: any, invoice: any) => {
      history.push("/invoices-detail/" + invoice.id);
    },
  };

  const searchCashFlowStatement = (values: any) => {
    if ((values.pdf && values.pdf == 1) || (values.excel && values.excel == 1)) {
      fetchFile(values)
    } else {
      fetchHtml(values)
    }
  };

  const fetchFile = (values: any) => {
    const type = (values.excel && values.excel == 1) ? "excel" : "pdf"
    
    toast
      .promise(request
        .get(Endpoints.cashflowstatement, { params: { sdate: values.sdate, edate: values.edate, type: type } }, "arraybuffer"), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = (values.excel && values.excel == 1) ? `CashFlowStatement-${+ new Date()}.xlsx` : `CashFlowStatement-${+ new Date()}.pdf`
        link.click()
      });
  }

  const fetchHtml = (values: any) => {
    toast
      .promise(request.get(Endpoints.cashflowstatement, { params: { sdate: values.sdate, edate: values.edate } }), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const { financing, operating, investing, totalRecords } = response.data;
        setOperating(operating);
        setFinancing(financing);
        setInvesting(investing);
        setTotalRecords(totalRecords);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const request = new ApiCore();

  const pageOptions = {
    sizePerPage: 25,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  const computeAmounts = (item: any) => {
    let totalAmount = 0;

    for (let i = 0; i < item.length; i++) {
      totalAmount += item[i].amount
    }
    return amountToCurrency(totalAmount);
  }

  const computeTotals = (item: any): number => {
    let totalAmount = 0;

    for (let i = 0; i < item.length; i++) {
      totalAmount += item[i].amount;
    }
    return totalAmount; 
  }

  const calcNetCash = (item1: any, item2: any, item3: any) => {
    item1 = computeTotals(item1);
    item2 = computeTotals(item2);
    item3 = computeTotals(item3);
    return  amountToCurrency(item1 + item2 + item3);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Cashflow Statement | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Cashflow" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Cashflow Statement</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col xl="12">
                      <SearchReport
                        sdate={invoiceStartDate()}
                        edate={invoiceEndDate()}
                        handleSearch={(values: any) => {
                          searchCashFlowStatement(values);
                        }}
                      />
                    </Col>
                  </Row>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th colSpan={2}><b>OPERATING ACTIVITIES</b></th>
                      </tr>
                      {operating && operating.map((item: any) => {
                        return (<>
                          <tr key={item.id}>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.name}</td>
                            <td className="text-center">{amountToCurrency(item.amount)}</td>
                          </tr>

                        </>)
                      })}
                      <tr>
                        <td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net Cash by Operating Activities</b></td>
                        <td className="text-center"><b>{computeAmounts(operating)}</b></td>
                      </tr>

                      <tr>
                        <th colSpan={2}><b>INVESTING ACTIVITIES</b></th>
                      </tr>
                      {investing && investing.map((item: any) => {
                        return (<>
                          <tr key={item.id}>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.name}</td>
                            <td className="text-center">{amountToCurrency(item.amount)}</td>
                          </tr>
                        </>)
                      })}

                      <tr>
                        <td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net Cash by Investing Activities</b></td>
                        <td className="text-center"><b>{computeAmounts(investing)}</b></td>
                      </tr>

                      <tr>
                        <th colSpan={2}><b>FINANCING ACTIVITIES</b></th>
                      </tr>

                      {financing && financing.map((item: any) => {
                        return (<>
                          <tr key={item.id}>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.name}</td>
                            <td className="text-center">{amountToCurrency(item.amount)}</td>
                          </tr>
                        </>)
                      })}

                      <tr>
                        <td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Net Cash by Financing Activities</b></td>
                        <td className="text-center"><b>{computeAmounts(financing)}</b></td>
                      </tr>

                      <tr>
                        <td><b>Net cash increase for period</b></td>
                        <td className="text-center"><b>{ calcNetCash(operating, investing, financing) }</b></td>
                      </tr>
                      <tr>
                        <td><b>Cash at the beginning of period</b></td>
                        <td className="text-center"><b>{"0"}</b></td>
                      </tr>
                      <tr>
                        <td><b>Cash at the end of period</b></td>
                        <td className="text-center"><b>{ calcNetCash(operating, investing, financing) }</b></td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CashflowStatement;
