import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { Endpoints } from "../../../utils/Endpoints";
import { ApiCore } from "../../../helpers/api_helper";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";

const SearchReport = (props: any) => {
  const params = new URLSearchParams(useLocation().search);


  let initialValues: any = {
    sdate: props.sdate,
    edate: props.edate,
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {(
            <>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={4}>
                          <XDatePickerField
                            key="sdate"
                            id="sdate"
                            name={"sdate"}
                            label={"Start Date"}
                            touched={touched}
                            onChange={setFieldValue}
                            value={values.sdate}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XDatePickerField
                            key="sdate"
                            id="edate"
                            name={"edate"}
                            label={"End Date"}
                            touched={touched}
                            onChange={setFieldValue}
                            value={values.edate}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <div>&nbsp;</div>
                          <XCheckbox
                            key="pdf"
                            id=""
                            name={"pdf"}
                            label={"PDF"}
                            value="1"
                            includeTopPadding={false}
                            onChange={setFieldValue}
                            size={{}} />
                        </Col>
                        <Col lg={1}>
                          <div>&nbsp;</div>
                          <XCheckbox
                            key="excel"
                            id=""
                            name={"excel"}
                            label={"Excel"}
                            value="1"
                            includeTopPadding={false}
                            onChange={setFieldValue}
                            size={{}} />
                        </Col>
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchReport;
