import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AxiosError, AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Endpoints } from "../../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import { invoiceEndDate, invoiceStartDate } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XSearchableSelect } from "../../../components/common/inputs/XSearchableSelect";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";

const SetSchoolFees = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [forms, setForms] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);
  const [accounts, setAccounts] = useState<any>([]);

  const history = useHistory();
  let initialValues: any = {};

  const terms: ISelectOption[] = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const LoadAccounts = (values: any) => {
    toast
      .promise(request
        .get(Endpoints.getSchoolFeesDefaults, {}), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        const { accounts } = response.data;
        setAccounts(accounts);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  };


  const request = new ApiCore();

  const setSchoolDues = (values: any) => {
    toast
      .promise(request
        .create(Endpoints.setSchoolFees, values), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        console.log(response)
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleSubmit = (values: any) => {
    console.log(values);
    setSchoolDues(values);
  };

  useEffect(() => {
    LoadAccounts({});
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.getSchoolFeesDefaults, {})
      .then((response: AxiosResponse) => {
        const { forms } = response.data;
        let options: any = [];
        options = forms.map((data: any) => ({
          value: data,
          label: data,
          data: JSON.stringify(data),
        }));
        setForms(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  useEffect(() => {
    request
      .get(Endpoints.getSchoolFeesDefaults, {})
      .then((response: AxiosResponse) => {
        const { categories } = response.data;
        let options: any = [];
        options = categories.map((data: any) => ({
          value: data.id,
          label: data.name,
          data: JSON.stringify(data),
        }));
        setCategories(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.getStudentsList, {})
      .then((response: AxiosResponse) => {
        const { students } = response.data;
        let options: any = [];
        options = students.map((data: any) => ({
          value: data.id,
          label: data.lastname + " " + data.firstname + " - " + data.studentid + " - " + data.form + " " + data.stream,
          data: JSON.stringify(data),
        }));
        setStudents(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Set School Fees | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="School" breadcrumbItem="Payable" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Set School Fees</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {!loading && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSearchableSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={forms}
                                    label={"Class"}
                                    onChange={(value: any) => {
                                      setFieldValue("form", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSearchableSelect
                                    key="category_id"
                                    id="category_id"
                                    name={"category"}
                                    options={categories}
                                    label={"Category"}
                                    onChange={(value: any) => {
                                      setFieldValue("category", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSearchableSelect
                                    key="student_id"
                                    id="student_id"
                                    name={"student_id"}
                                    options={students}
                                    label={"Student"}
                                    onChange={(value: any) => {
                                      setFieldValue("student_id", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    id="year"
                                    type={"text"}
                                    name={"year"}
                                    label={"Year"}
                                    placeholder="2014"
                                    onChange={(value: any) => {
                                      setFieldValue("year", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                              </Row>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th><b>Account Name</b></th>
                                    <th className="text-center"><b>Amount</b></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {accounts && accounts.map((item: any) => {
                                    return (<><tr key={item.id}>
                                      <td>{item.name}</td>
                                      <td className="text-center">
                                        <XTextBox
                                          id={`account_${item.id}`}
                                          type={"text"}
                                          name={`account[account_${item.id}]`}
                                          touched={touched}
                                          errors={errors}
                                        />
                                      </td>
                                    </tr>
                                    </>)
                                  })}
                                </tbody>
                              </table>
                              <Row>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                  <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                            <hr />
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SetSchoolFees;
