import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { Endpoints } from "../../utils/Endpoints";
import { ApiCore } from "../../helpers/api_helper";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";
import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useHistory } from "react-router-dom";

const SearchInvoice = (props: any) => {
  const [defaults, setDefaults] = useState<any>({});
  const [companies, setCompanies] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";

  const history = useHistory();

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.defaults)
      .then((response: AxiosResponse) => {
        setDefaults(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.companies, {})
      .then((response: AxiosResponse) => {
        const { companies, totalRecords } = response.data;
        let options: any = [];
        options = companies.map((data: any) => ({
          value: data.id,
          text: data.name || data.taxid,
          data: JSON.stringify(data),
        }));
        setCompanies(options);
        initialValues.company_id = "";
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  let initialValues: any = {
    company_id: "",
    sdate: props.sdate,
    edate: props.edate,
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading && <Loading label="Loading invoice. Please wait." />}
          {!loading && (
            <>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XDatePickerField
                            key="sdate"
                            id="sdate"
                            name={"sdate"}
                            label={"Start Date"}
                            touched={touched}
                            onChange={setFieldValue}
                            value={values.sdate}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XDatePickerField
                            key="sdate"
                            id="edate"
                            name={"edate"}
                            label={"End Date"}
                            touched={touched}
                            onChange={setFieldValue}
                            value={values.edate}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XSelect
                            key="company_id"
                            id="company_id"
                            name={"company_id"}
                            options={companies}
                            dataType={"companies"}
                            label={"Company"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchInvoice;
